import { IODataType } from "types/iodata";

//Workflow policies
export const WorkflowRetentionPeriodPolicyName       = "retentionPeriod"
export const MaxWorkflowExecutionsPolicyName = "maxWorkflowExecutions"
export const ParameterStoreEdgePolicyName    = "parameterStoreEdge"


//Search policies
export const SearchRetentionPeriodPolicyName       = "retentionPeriod"
export const MaxSearchExecutionsPolicyName = "maxSearchExecutions"
export const MaxSearchActionExecutionsPolicyName    = "maxSearchActionExecutions"

//Casemgmt policies
export const CaseMgmtRetentionPeriodPolicyName       = "retentionPeriod"
export const CaseMgmtSLADurationPolicyName = "slaDuration"


//Actions - actions on Workflow
export enum PolicyCategoryType {
    WorkflowGeneral = "workflow.policies.general",
    SearchGeneral = "search.policies.general", 
    CaseMgmtGeneral = "casemgmt.policies.general",    
    ActionProviders = "workflow.policies.action.providers",
    TriggerProviders = "workflow.policies.trigger.providers",
    ArtifactProviders = "search.policies.artifact.providers",
}

export enum PolicyDataType {
    String = "string",
    Numeric = "numeric",
    Boolean = "boolean",
    Array = "array",
    Map = "map",
}

export interface PolicyValue {
    type: PolicyDataType;
    data: any;
    min?: number;
    max?: number;
    keyName?: string;
    valueName?: string;
}

export interface Policy {
    id: string;
    name : string;
    displayName : string;
    description: string;
    value: PolicyValue;
    editable: boolean;
    hidden: boolean;
    category: string;
    tenantID: string;
}

export interface PolicyStore {
    policies: Map<PolicyCategoryType, Policy[]>;

    getPolicies: (type: PolicyCategoryType) => Promise<Policy[]>;
    updatePolicy: (type: PolicyCategoryType, policy: Policy) => Promise<void>;
    createPolicy: (type: PolicyCategoryType, policy: Policy) => Promise<void>;
    deletePolicy: (type: PolicyCategoryType, policyId: string) => Promise<void>;
    getPolicy: (type: PolicyCategoryType, name: string, refresh?: boolean) => Promise<Policy|undefined>;
}