import signupRighSideIconPng from "./signup/SignupRighSide.png";
import signupRightSideBackGroundIconPng from "./signup/SignupRightSideBackGround.png";

export const signupIcons = {
    signupRighSideIconPng,
    signupRightSideBackGroundIconPng
};

import { ReactComponent as searchIcon}  from "./search/Search.svg";

export const searchIcons = {
    searchIcon,
};

import { ReactComponent as dashboardAdd } from "./dashboard/add-dashboard.svg";
import { ReactComponent as dashboardDelete } from "./dashboard/delete-dashboard.svg";
import { ReactComponent as dashboardScreenshot } from "./dashboard/screenshot-dashboard.svg";
import { ReactComponent as dashboardRefresh } from "./dashboard/refresh-dashboard.svg";
import { ReactComponent as metricScreenshot} from "./dashboard/screenshot-metric.svg";
import { ReactComponent as metricAdd } from "./dashboard/add-metric.svg";
import { ReactComponent as layoutEdit } from "./dashboard/edit-layout.svg";
import { ReactComponent as layoutSave } from "./dashboard/save-layout.svg";

export const dashboardIcons = {
    dashboardAdd,
    dashboardDelete,
    dashboardScreenshot,
    dashboardRefresh,
    metricScreenshot,
    metricAdd,
    layoutEdit,
    layoutSave
};

import { ReactComponent as workspaceWorkflow} from "./workspace/Workflow.svg";
import { ReactComponent as workspaceSearch } from "./workspace/Search.svg";
import { ReactComponent as workspaceAction } from "./workspace/Action.svg";
import { ReactComponent as workspaceApproval } from "./workspace/Approval.svg";


export const workspaceIcons = {
    workspaceWorkflow,
    workspaceSearch,
    workspaceAction,
    workspaceApproval
};

import { ReactComponent as workflowShortIcon} from "./workflow/Workflow-Short.svg";
import { ReactComponent as subWorkflowShortIcon} from "./workflow/Subworkflow-Short.svg";
import { ReactComponent as stepsShortIcon} from "./workflow/Steps-Short.svg";
import { ReactComponent as actionShortIcon } from "./workflow/Action-Short.svg";
import { ReactComponent as triggerShortIcon } from "./workflow/Trigger-Short.svg";
import { ReactComponent as triggerShortDarkIcon } from "./workflow/Trigger-Short-Dark.svg";
import { ReactComponent as conditionShortOperatorIcon} from "./workflow/Condition-Short.svg";
import { ReactComponent as loopShortOperatorIcon }from "./workflow/Loop-Short.svg";
import { ReactComponent as waitShortOperatorIcon } from "./workflow/Wait-Short.svg";
import { ReactComponent as parallelShortOperatorIcon} from "./workflow/Parallel-Short.svg";
import { ReactComponent as joinShortOperatorIcon } from "./workflow/Join-Short.svg";
import { ReactComponent as breakShortOperatorIcon} from "./workflow/Break-Short.svg";
import { ReactComponent as kvShortOperatorIcon} from "./workflow/KV-Short.svg";
import { ReactComponent as searchShortOperatorIcon} from "./workflow/Search-Short.svg";
import { ReactComponent as approvalShortOperatorIcon} from "./workflow/Approval-Short.svg";
import { ReactComponent as subworkflowShortOperatorIcon} from "./workflow/Subworkflow-Short.svg";
import { ReactComponent as httpShortOperatorIcon} from "./workflow/Http-Short.svg";
import { ReactComponent as dbShortOperatorIcon} from "./workflow/DB-short.svg";
import { ReactComponent as aiShortOperatorIcon} from "./workflow/AI-Short.svg";
import { ReactComponent as parametersShortIcon} from "./workflow/Parameters-Short.svg";
import { ReactComponent as outputsShortIcon} from "./workflow/Outputs-Short.svg";
import { ReactComponent as appsShortIcon} from "./workflow/Apps-Short.svg";
import { ReactComponent as rulesShortIcon} from "./workflow/Rules-Short.svg";
import { ReactComponent as stepsSidebarCollapse} from "./workflow/StepsSidebarCollapse.svg";

import { ReactComponent as actionIcon } from  "./workflow/Action-D21D71.svg";
import { ReactComponent as actionSidebarDarkIcon } from  "./workflow/Action-Sidebar-Dark-D21D71.svg";
import { ReactComponent as actionSidebarLightIcon } from  "./workflow/Action-Sidebar-Light-D21D71.svg";
import { ReactComponent as triggerIcon } from  "./workflow/Trigger-26387D.svg";
import { ReactComponent as conditionOperator } from  "./workflow/Condition-Operator-6B0BEA.svg";
import { ReactComponent as loopOperator } from  "./workflow/Loop-Operator-6B0BEA.svg";
import { ReactComponent as waitOperator } from  "./workflow/Wait-Operator-6B0BEA.svg";
import { ReactComponent as parallelOperator } from  "./workflow/Parallel-Operator-6B0BEA.svg";
import { ReactComponent as joinOperator } from  "./workflow/Join-Operator-6B0BEA.svg";
import { ReactComponent as endOperator } from  "./workflow/End-Operator.svg";
import { ReactComponent as breakOperator } from  "./workflow/Break-Operator-6B0BEA.svg";
import { ReactComponent as kvOperator } from  "./workflow/KV-Operator-6B0BEA.svg";
import { ReactComponent as searchOperator } from  "./workflow/Search-Operator-6B0BEA.svg";
import { ReactComponent as approvalOperator } from  "./workflow/Approval-Operator-6B0BEA.svg";
import { ReactComponent as subworkflowOperator } from  "./workflow/Subworkflow-Operator-6B0BEA.svg";
import { ReactComponent as httpOperator } from  "./workflow/Http-Operator-6B0BEA.svg";
import { ReactComponent as dbOperator } from  "./workflow/DB-Operator-6B0BEA.svg";
import { ReactComponent as aiOperator } from  "./workflow/AI-Operator-6B0BEA.svg";

import { ReactComponent as logBlankIcon } from  "./workflow/Log-Blank.svg";
import { ReactComponent as logSuccessIcon } from  "./workflow/Log-Success.svg";
import { ReactComponent as logProgressIcon } from  "./workflow/Log-Progress.svg";
import { ReactComponent as logPendingApprovalIcon } from  "./workflow/Log-PendingApproval.svg";
import { ReactComponent as logFailedIcon } from  "./workflow/Log-Failed.svg";
import { ReactComponent as logSettingsIcon } from  "./workflow/Log-Settings.svg";

export const workflowIcons = {
    workflowShortIcon,
    subWorkflowShortIcon,
    stepsShortIcon,
    actionShortIcon,
    triggerShortIcon,
    triggerShortDarkIcon,
    conditionShortOperatorIcon,
    loopShortOperatorIcon,
    waitShortOperatorIcon,
    parallelShortOperatorIcon,
    joinShortOperatorIcon,
    breakShortOperatorIcon,
    kvShortOperatorIcon,
    searchShortOperatorIcon,
    approvalShortOperatorIcon,
    subworkflowShortOperatorIcon,
    httpShortOperatorIcon,
    dbShortOperatorIcon,
    aiShortOperatorIcon,
    parametersShortIcon,
    outputsShortIcon,
    appsShortIcon,
    rulesShortIcon,
    actionIcon,
    actionSidebarDarkIcon,
    actionSidebarLightIcon,
    triggerIcon,
    conditionOperator,
    loopOperator,
    waitOperator,
    parallelOperator,
    joinOperator,
    endOperator,
    searchOperator,
    breakOperator,
    kvOperator,
    approvalOperator,
    subworkflowOperator,
    httpOperator,
    dbOperator,
    aiOperator,
    logBlankIcon,
    logSuccessIcon,
    logProgressIcon,
    logPendingApprovalIcon,
    logFailedIcon,
    logSettingsIcon,
    stepsSidebarCollapse
};

import { ReactComponent as appDisabledIcon } from  "./app/App-Disabled.svg";
import { ReactComponent as appEnabledIcon } from  "./app/App-Enabled.svg";
import { ReactComponent as appErrorIcon } from  "./app/App-Error.svg";
import { ReactComponent as appInprogressIcon } from  "./app/App-InProgress.svg";
import { ReactComponent as appCardExtraIcon } from  "./app/App-Too-Right.svg";
import { ReactComponent as appMoreOutlinedIcon } from  "./app/App-MoreOutlined.svg";
import { ReactComponent as appConfigTabIcon } from "./app/App-Config-Tab.svg";

export const appIcons = {
    appDisabledIcon,
    appEnabledIcon,
    appErrorIcon,
    appInprogressIcon,
    appCardExtraIcon,
    appMoreOutlinedIcon,
    appConfigTabIcon,
};

import { ReactComponent as edgeUnavailableStatusIcon } from  "./edge/Edge-Card-Bottom-Left-Unavailable.svg";
import { ReactComponent as edgeAvailableStatusIcon } from  "./edge/Edge-Card-Bottom-Left-Available.svg";
import { ReactComponent as edgeUnknownStatusIcon } from  "./edge/Edge-Card-Bottom-Left-Unknown.svg";
import { ReactComponent as edgeOperationIcon } from  "./edge/Edge-Card-Bottom-Left-Operation.svg";
import { ReactComponent as edgeEnabledIcon } from  "./edge/Edge-Enabled.svg";
import { ReactComponent as edgeDisabledIcon } from  "./edge/Edge-Disabled.svg";
import { ReactComponent as customEdgeIcon  } from  "./edge/Custom-Edges.svg";
import { ReactComponent as hypredgeManagedIcon  } from  "./edge/HyprEdge-Managed.svg";
import { ReactComponent as multiCloudEdgeIcon } from  "./edge/MultiCloud.svg";
import { ReactComponent as cloudEdgeIcon } from  "./edge/Cloud-Edge.svg";
import { ReactComponent as onPremiseEdgeShortIcon } from  "./edge/OnPremise-Edge-Short.svg";
import { ReactComponent as onPremiseEdgeIcon } from  "./edge/OnPremise-Edge.svg";
import { ReactComponent as onPremiseEdgeDarkModeIcon } from  "./edge/OnPremise-Edge-Dark-Mode.svg";

export const edgeIcons = {
    edgeUnavailableStatusIcon,
    edgeAvailableStatusIcon,
    edgeUnknownStatusIcon,
    edgeOperationIcon,
    edgeEnabledIcon,
    edgeDisabledIcon,
    customEdgeIcon,
    hypredgeManagedIcon,
    multiCloudEdgeIcon,
    multiCloudLogoSvg,
    onPremiseShortLogoSvg,
    onPremiseLogoSvg,
    onPremiseDarkModeLogoSvg,
    cloudEdgeIcon,
    onPremiseEdgeShortIcon,
    onPremiseEdgeIcon,
    onPremiseEdgeDarkModeIcon,
};

import { ReactComponent as caseMgmtCasesIcon } from  "./casemgmt/cases.svg";
import { ReactComponent as caseMgmtInfoIcon } from  "./casemgmt/info.svg";
import { ReactComponent as caseMgmtNotesIcon } from  "./casemgmt/notes.svg";
import { ReactComponent as caseMgmtAttachmentsIcon } from  "./casemgmt/attachments.svg";
import { ReactComponent as caseMgmtLinksIcon } from  "./casemgmt/links.svg";
import { ReactComponent as caseMgmtTasksIcon } from "./casemgmt/tasks.svg";

export const caseMgmtIcons = {
    caseMgmtCasesIcon,
    caseMgmtInfoIcon,
    caseMgmtNotesIcon,
    caseMgmtAttachmentsIcon,
    caseMgmtLinksIcon,
    caseMgmtTasksIcon,
};

import nowIconPng from "./common/Now.png";

import { ReactComponent as donutChartIcon }   from "./common/DonutChart.svg";
import { ReactComponent as directionsIcon }  from "./common/arrows-move.svg";
import { ReactComponent as pencilEdteIcon } from  "./common/Pencil-Edit.svg";
import { ReactComponent as edgeIcon } from  "./common/Edge.svg";
import { ReactComponent as webhookIcon } from  "./common/Webhook.svg";
import { ReactComponent as parametersIcon } from  "./common/Parameters.svg";
import { ReactComponent as aiIcon } from  "./common/AI.svg";
import { ReactComponent as databaseIcon } from  "./common/Database.svg";
import { ReactComponent as httpIcon } from  "./common/http.svg";
import { ReactComponent as httpsIcon } from  "./common/https.svg";
import { ReactComponent as httpHeadersIcon } from  "./common/Http-Headers.svg";
import { ReactComponent as preferredIcon } from  "./common/Preferred.svg";
import { ReactComponent as filterIcon } from  "./common/Filter.svg";
import { ReactComponent as filterClearIcon } from  "./common/Filter-Clear.svg";
import { ReactComponent as filterSortIcon } from  "./common/Filter-Sort.svg";
import { ReactComponent as tagsFilterIcon } from  "./common/Tags-Filter.svg";
import { ReactComponent as moreOutlinedIcon } from  "./common/MoreOutlined.svg";

import { ReactComponent as savedIcon } from  "./common/Saved.svg";
import { ReactComponent as recentIcon } from  "./common/Recent.svg";
import { ReactComponent as popularIcon } from  "./common/Popular.svg";
import { ReactComponent as propertiesIcon } from  "./common/Properties.svg";
import { ReactComponent as logsIcon } from  "./common/Logs.svg";
import { ReactComponent as loopIcon } from  "./common/Loop.svg";

import { ReactComponent as backIcon } from  "./common/Back-Icon.svg";
import { ReactComponent as editIcon } from  "./common/Edit.svg";
import { ReactComponent as zoomIn } from  "./common/ZoomIn.svg";
import { ReactComponent as zoomOut } from  "./common/ZoomOut.svg";
import { ReactComponent as fitView } from  "./common/FitView.svg";
import { ReactComponent as clipBoardIcon } from  "./common/ClearClipboard.svg";
import { ReactComponent as cameraIcon } from  "./common/cameraIcon.svg";
import { ReactComponent as templateIcon } from "./common/Template.svg";
import { ReactComponent as jsonIcon } from "./common/Json.svg";
import { ReactComponent as mapViewIcon } from "./common/MapView.svg";
import coPilotLightModeSvg from "./common/CoPilotLogoLightMode.svg";
import coPilotDarkModeSvg  from "./common/CoPilotLogoDarkMode.svg";

export const commonIcons = {    
    nowIconPng,
    donutChartIcon,
    directionsIcon,
    pencilEdteIcon,
    edgeIcon,
    parametersIcon,
    aiIcon,
    databaseIcon,
    webhookIcon,
    httpIcon,
    httpsIcon,
    httpHeadersIcon,
    preferredIcon,
    filterIcon,
    filterClearIcon,
    filterSortIcon,
    tagsFilterIcon,
    moreOutlinedIcon,
    savedIcon,
    recentIcon,
    popularIcon,
    propertiesIcon,
    logsIcon,
    loopIcon,
    backIcon,
    editIcon,
    zoomIn,
    zoomOut,
    fitView,
    clipBoardIcon,
    templateIcon,
    jsonIcon,
    mapViewIcon,
    coPilotLightModeSvg,
    coPilotDarkModeSvg,
    cameraIcon
};

import hyprEdgeLogoShortSvg from  "./company/HyprEdge-Logo-Short.svg";
import hyprEdgeLogoSvg from  "./company/HyprEdge-Logo-Web.svg";
import hyprEdgeLogoDarkSvg from "./company/HyprEdge-Logo-Web-Dark.svg";
import awsLogoSvg from "./company/AWS.svg";
import azureLogoSvg from "./company/Azure.svg";
import googleCloudLogoSvg from "./company/GoogleCloud.svg";
import oracleLogoSvg from "./company/Oracle.svg";
import multiCloudLogoSvg from  "./edge/MultiCloud.svg";
import onPremiseShortLogoSvg from  "./edge/OnPremise-Edge-Short.svg";
import onPremiseLogoSvg from  "./edge/OnPremise-Edge.svg";
import onPremiseDarkModeLogoSvg from "./edge/OnPremise-Edge-Dark-Mode.svg";
import awsDarkLogoSvg from "./company/AWS_Dark.svg";
import azureDarkLogoSvg from "./company/Azure_Dark.svg";
import googleCloudDarkLogoSvg from "./company/GoogleCloud_Dark.svg";
import oracleDarkLogoSvg from "./company/Oracle_Dark.svg";


import { ReactComponent as hyprEdgeShortIcon } from  "./company/HyprEdge-Logo-Short.svg";
import { ReactComponent as hyprEdgeIcon } from  "./company/HyprEdge-Logo.svg";
import { ReactComponent as hyprEdgeManagedIcon } from  "./company/HyprEdge-Managed.svg";
import { ReactComponent as awsAppEdgeIcon} from "./company/AWS-App-Edge.svg";
import { ReactComponent as awsShortIcon } from  "./company/AWS-Short.svg";
import { ReactComponent as azureAppEdgeIcon } from "./company/Azure-App-Edge.svg";
import { ReactComponent as azureShortIcon } from  "./company/Azure-Short.svg";
import { ReactComponent as googleCloudAppEdgeIcon} from "./company/GoogleCloud-App-Edge.svg";
import { ReactComponent as googleCloudShortIcon } from  "./company/GoogleCloud-Short.svg";
import { ReactComponent as oracleAppEdgeIcon } from "./company/Oracle-App-Edge.svg";
import { ReactComponent as oracleShortIcon } from  "./company/Oracle-Short.svg";

import gmailIcon from "./company/Gmail.png";
import microsoftIcon from "./company/Microsoft.png";
import logo1Icon from "./company/logo-1.png";
import logo2Icon from "./company/logo-2.png";
import logo3Icon from "./company/logo-3.png";
import logo4Icon from "./company/logo-4.png";
import logo5Icon from "./company/logo-5.png";
import logo6Icon from "./company/logo-6.png";
import logo7Icon from "./company/logo-7.png";
import logo8Icon from "./company/logo-8.png";

export const companyIcons = {
    awsAppEdgeIcon,
    awsShortIcon,
    azureAppEdgeIcon,
    azureShortIcon,
    googleCloudAppEdgeIcon,
    googleCloudShortIcon,
    oracleAppEdgeIcon,
    oracleShortIcon,
    gmailIcon,
    microsoftIcon,
    logo1Icon,
    logo2Icon,
    logo3Icon,
    logo4Icon,
    logo5Icon,
    logo6Icon,
    logo7Icon,
    logo8Icon,
    hyprEdgeShortIcon,
    hyprEdgeIcon,
    hyprEdgeManagedIcon,
    hyprEdgeLogoSvg,hyprEdgeLogoDarkSvg, hyprEdgeLogoShortSvg ,
    awsLogoSvg ,azureLogoSvg ,googleCloudLogoSvg ,oracleLogoSvg,
    awsDarkLogoSvg,azureDarkLogoSvg, googleCloudDarkLogoSvg,oracleDarkLogoSvg
}


import { ReactComponent as menuCollapseExpandIcon } from  "./menu/Menu-Collapse-Expand.svg";
import { ReactComponent as menuCollapseExpandHoverIcon } from  "./menu/Menu-Collapse-Expand-hover.svg";
import { ReactComponent as menuHomeIcon } from  "./menu/Menu-Home.svg";
import { ReactComponent as menuHomeHoverIcon } from  "./menu/Menu-Home-hover.svg";
import { ReactComponent as menuHomeSelectedIcon } from  "./menu/Menu-Home-Selected.svg";
import { ReactComponent as menuDashboardIcon } from  "./menu/Menu-Dashboard.svg";
import { ReactComponent as menuDashboardHoverIcon } from  "./menu/Menu-Dashboard-hover.svg";
import { ReactComponent as menuDashboardSelectedIcon } from  "./menu/Menu-Dashboard-Selected.svg";
import { ReactComponent as menuWorkspaceIcon } from  "./menu/Menu-Workspace.svg";
import { ReactComponent as menuWorkspaceHoverIcon } from  "./menu/Menu-Workspace-hover.svg";
import { ReactComponent as menuWorkspaceSelectedIcon } from  "./menu/Menu-Workspace-selected.svg";
import { ReactComponent as menuHyprFlowsIcon } from  "./menu/Menu-HyprFlows.svg";
import { ReactComponent as menuHyprFlowsHoverIcon } from  "./menu/Menu-HyprFlows-hover.svg";
import { ReactComponent as menuHyprFlowsSelectedIcon } from  "./menu/Menu-HyprFlows-selected.svg";
import { ReactComponent as menuSearchIcon } from  "./menu/Menu-Search.svg";
import { ReactComponent as menuSearchHoverIcon } from  "./menu/Menu-Search-hover.svg";
import { ReactComponent as menuSearchSelectedIcon } from  "./menu/Menu-Search-selected.svg";
import { ReactComponent as menuCasesIcon } from  "./menu/Menu-Cases.svg";
import { ReactComponent as menuCasesHoverIcon } from  "./menu/Menu-Cases-hover.svg";
import { ReactComponent as menuCasesSelectedIcon } from  "./menu/Menu-Cases-selected.svg";
import { ReactComponent as menuEdgesIcon } from  "./menu/Menu-Edges.svg";
import { ReactComponent as menuEdgesHoverIcon } from  "./menu/Menu-Edges-Hover.svg";
import { ReactComponent as menuEdgesSelectedIcon } from  "./menu/Menu-Edges-Selected.svg";
import { ReactComponent as menuAppsIcon } from  "./menu/Menu-Apps.svg";
import { ReactComponent as menuAppsHoverIcon } from  "./menu/Menu-Apps-Hover.svg";
import { ReactComponent as menuAppsSelectedIcon } from  "./menu/Menu-Apps-Selected.svg";
import { ReactComponent as menuUsersAndRolesIcon } from  "./menu/Menu-Users-and-roles.svg";
import { ReactComponent as menuUsersAndRolesHoverIcon } from  "./menu/Menu-Users-and-roles-hover.svg";
import { ReactComponent as menuUsersAndRolesSelectedIcon } from  "./menu/Menu-Users-and-roles-selected.svg";
import { ReactComponent as menuAdministrationIcon } from  "./menu/Menu-Administration.svg";
import { ReactComponent as menuAdministrationHoverIcon } from  "./menu/Menu-Administration-hover.svg";
import { ReactComponent as menuAdministrationSelectedIcon } from  "./menu/Menu-Administration-Selected.svg";
import { ReactComponent as menuDeveloperIcon } from  "./menu/Menu-Developer.svg";
import { ReactComponent as menuDeveloperHoverIcon } from  "./menu/Menu-Developer-Hover.svg";
import { ReactComponent as menuDeveloperSelectedIcon } from  "./menu/Menu-Developer-Selected.svg";
import { ReactComponent as menuManagedModeIcon } from  "./menu/Menu-ManagedMode.svg";
import { ReactComponent as menuManagedModeHoverIcon } from  "./menu/Menu-ManagedMode-Hover.svg";
import { ReactComponent as menuManagedModeSelectedIcon } from  "./menu/Menu-ManagedMode-Selected.svg";


export const menuIcons = {
    menuCollapseExpandHoverIcon,
    menuCollapseExpandIcon,
    menuHomeIcon,
    menuHomeHoverIcon,
    menuHomeSelectedIcon,
    menuDashboardIcon,
    menuDashboardHoverIcon,
    menuDashboardSelectedIcon,
    menuWorkspaceIcon,
    menuWorkspaceHoverIcon,
    menuWorkspaceSelectedIcon,
    menuHyprFlowsIcon,
    menuHyprFlowsHoverIcon,
    menuHyprFlowsSelectedIcon,
    menuSearchIcon,
    menuSearchHoverIcon,
    menuSearchSelectedIcon,
    menuCasesIcon,
    menuCasesHoverIcon,
    menuCasesSelectedIcon,
    menuEdgesIcon,
    menuEdgesHoverIcon,
    menuEdgesSelectedIcon,
    menuAppsIcon,
    menuAppsHoverIcon,
    menuAppsSelectedIcon,
    menuUsersAndRolesIcon,
    menuUsersAndRolesHoverIcon,
    menuUsersAndRolesSelectedIcon,
    menuAdministrationIcon,
    menuAdministrationHoverIcon,
    menuAdministrationSelectedIcon,
    menuDeveloperIcon,
    menuDeveloperHoverIcon,
    menuDeveloperSelectedIcon,
    menuManagedModeIcon,
    menuManagedModeHoverIcon,
    menuManagedModeSelectedIcon
};

import { ReactComponent as metricApps } from   "./metrics/Metric-Apps.svg";
import { ReactComponent as metricEdges } from   "./metrics/Metric-Edges.svg";
import { ReactComponent as metricWorkflows } from   "./metrics/Metric-HyprFlow.svg";
import { ReactComponent as metricWorkflowExecutions } from   "./metrics/Metric-HyprFlow.svg";
import { ReactComponent as metricActions } from   "./metrics/Metric-Actions.svg";
import { ReactComponent as metricCases } from   "./metrics/Metric-Cases.svg";
import { ReactComponent as metricSearch } from   "./metrics/Metric-Search.svg";
import { ReactComponent as metricSearchExecutions } from   "./metrics/Metric-Search.svg";
import { ReactComponent as metricIOSaved } from   "./metrics/Metric-IO-Saved.svg";
import { ReactComponent as metricTimeSaved } from   "./metrics/Metric-Time-Saved.svg";
import { ReactComponent as metricUsers } from   "./metrics/Metric-Users.svg";
import { ReactComponent as metricRoles } from   "./metrics/Metric-Roles.svg";
import { ReactComponent as metricPermissionSets } from   "./metrics/Metric-Permissions.svg";
import { ReactComponent as metricRequests } from   "./metrics/Metric-Requests.svg";
import { ReactComponent as metricDenies } from   "./metrics/Metric-Denies.svg";
import { ReactComponent as metricResources } from   "./metrics/Metric-Resources.svg";
import { ReactComponent as metricUp } from   "./metrics/Metric-Up.svg";
import { ReactComponent as metricDown } from   "./metrics/Metric-Down.svg";

export const metricsIcons = {
    metricApps,
    metricEdges,
    metricWorkflows,
    metricWorkflowExecutions,
    metricActions,
    metricSearch,
    metricSearchExecutions,
    metricIOSaved,
    metricTimeSaved,
    metricUsers,
    metricRoles,
    metricPermissionSets,
    metricRequests,
    metricDenies,
    metricResources,
    metricCases,

    
};

import { ReactComponent as activityIcon } from "./activity/Activity-Icon.svg";

export const activityIcons = {
    activityIcon,
}

import { ReactComponent as usersShortIcon }  from "./rbac/Users-Short.svg";
import { ReactComponent as rolesShortIcon } from "./rbac/Roles-Short.svg";
import { ReactComponent as permissionsShortIcon } from "./rbac/Permissions-Short.svg";

export const rbacIcons = {
    usersShortIcon,
    rolesShortIcon,
    permissionsShortIcon,
}

import { ReactComponent as adminPoliciesIcon } from "./administration/Admin-Policies.svg";
import { ReactComponent as adminSSOIcon } from "./administration/Admin-SSO.svg";
import { ReactComponent as adminParamterStoreIcon } from "./administration/Parameter-Store.svg";


export const administrationIcons = {
    adminPoliciesIcon,
    adminSSOIcon,
    adminParamterStoreIcon,
}

import { ReactComponent as goalsConfigureAppsIcon } from "./goals/Goal-Configure-Apps.svg";
import { ReactComponent as goalsConfigureEdgesIcon } from "./goals/Goal-Configure-Edges.svg";
import { ReactComponent as goalsCreateGoalsIcon } from "./goals/Goal-Create-Goals.svg";
import { ReactComponent as goalsCreateHyprFlowsIcon } from "./goals/Goal-Create-HyprFlows.svg";
import { ReactComponent as goalsInviteUsersIcon } from "./goals/Goal-Invite-Users.svg";
import { ReactComponent as goalsTrySearchIcon } from "./goals/Goal-Try-Search.svg";

export const goalsIcons = {
    goalsConfigureAppsIcon,
    goalsConfigureEdgesIcon,
    goalsCreateGoalsIcon,
    goalsCreateHyprFlowsIcon,
    goalsInviteUsersIcon,
    goalsTrySearchIcon,
}

import { ReactComponent as managedModeSettings } from "./managedmode/ManagedMode-Settings.svg";
import { ReactComponent as managedModeTags } from "./managedmode/ManagedMode-Tags.svg";
import { ReactComponent as managedModeMsp } from "./managedmode/ManagedMode-Msp.svg";
import { ReactComponent as managedModeCustomer } from "./managedmode/ManagedMode-Customer.svg";
export const managedmodeIcons = {
    managedModeSettings,
    managedModeTags,
    managedModeMsp,
    managedModeCustomer,
}