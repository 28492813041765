import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Typography, theme } from "antd";
import { SvgIcon } from "components/SvgIcon";
import { VideoPlayer } from "components/VideoPlayer";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

export interface StarterCardProps {
  id: string;
  category: string;
  duration: string;
  cta: string;
  description: string;
  icon: FunctionComponent;
  destination: string;
  backgroundColor: string;
  videoUrl: string;
  thumbnailUrl: string;
}

export const StarterCard = (props: StarterCardProps) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        backgroundColor: props.backgroundColor,
        borderColor: token.colorBorder,
        height: "100%",
      }}
    >
      <Card
        style={{
          flex: 1,
          backgroundColor: props.backgroundColor,
          border: "none",
        }}
        bodyStyle={{
          padding: token.padding,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <div id="starter-card-image">
            <SvgIcon
              component={props.icon}
              size={token.fontSizeHeading1}
              style={{
                width: "60px",
                height: "60px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            ></SvgIcon>
          </div>
          <div id="starter-card-title">
            <Text strong>{props.category}</Text>
            <Text>{` (${props.duration})`}</Text>
          </div>
          <div
            id="starter-card-cta"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text style={{ fontSize: token.fontSizeHeading4 }}>
              {props.cta}
            </Text>
            <ArrowRightOutlined
              style={{
                fontSize: token.fontSizeHeading5,
                color: token.colorText,
              }}
              onClick={() => navigate(props.destination)}
            ></ArrowRightOutlined>
          </div>
          <div id="starter-card-description">
            <Text>{props.description}</Text>
          </div>
        </div>
      </Card>
      <VideoPlayer
        backgroundColor={props.backgroundColor}
        videoUrl={props.videoUrl}
        thumbnailUrl={props.thumbnailUrl}
        cta={props.cta}
      />
    </div>
  );
};
