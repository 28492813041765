import { FC, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";

import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./EmailOtpVerification.module.scss";
import { notification } from "utility/notification";

const EmailOtpVerification: FC = () => {
  const INITIAL_TIMER = 60;

  const [otp, setOtp] = useState<string>("");
  const [timer, setTimer] = useState<number>(INITIAL_TIMER);

  useEffect(() => {
    if (timer <= 0) return;
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  }, [timer]);

  const navigate = useNavigate();

  useEffect(() => {
    if (otp.length > 5) {
      // on success
      notification.success({
        message: "OTP verified",
        description:
          "Your OTP has been verified successfully, and you may now setup your password",
        duration: 6,
      });
      navigate("/signup-set-password");

      // on error
      // notification.error({
      //   message: "INCORRECT OTP",
      //   description:
      //     "Your entered OTP is incorrect, please enter the right OTP",
      //   duration: 6,
      // });
      // setOtp("");
    }
  }, [otp]);

  const resendOtp = () => {
    if (timer === 0)
      // after the API call is done
      setTimer(INITIAL_TIMER);
  };

  return (
    <SignInSignUpWrapper>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <MailOutlined />
        </div>

        <h2 className={styles.verificationTitle}>VERIFICATION</h2>
        <p className={styles.verificationDescription}>
          We will send you a{" "}
          <span className={styles.bold}>one time password</span> on your Email
          address
        </p>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            fontSize: "24px",
            width: "25%",
            height: "36px",
            margin: "4px auto",
            borderTop: "0px",
            borderLeft: "0px",
            borderRight: "0px",
            outline: "none",
            borderColor: "#000a46",
          }}
          containerStyle={{
            margin: "20px auto",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          renderInput={(props, index) => <input type="password" />}
        />
        <p className={styles.timer}>{timer}</p>
        <p>
          {"Don't"} receive the OTP?{" "}
          <span className={styles.resndOTPlink} onClick={resendOtp}>
            Resend OTP
          </span>{" "}
        </p>
      </div>
    </SignInSignUpWrapper>
  );
};

export default EmailOtpVerification;
