import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { Policy, PolicyStore, PolicyCategoryType } from "types";

import {
  createSearchArtifactProviderPolicyApi,
  createWorkflowProviderPolicyApi,
  deleteSearchArtifactProviderPolicyApi,
  deleteWorkflowProviderPolicyApi,
  getSearchPoliciesApi,
  getCaseMgmtPoliciesApi,
  getWorkflowPoliciesApi, updateCaseMgmtPolicyApi, updateSearchPolicyApi, updateWorkflowPolicyApi,
} from "api";

export const usePolicyStore = create<PolicyStore>()(
  devtools((set, get) => ({
    policies: new Map<PolicyCategoryType, Policy[]>(),

    getPolicies: async (type: PolicyCategoryType) => {
      try {
        let policyList = [] as Policy[];

        switch (type) {
          case PolicyCategoryType.WorkflowGeneral:
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.WorkflowGeneral);
            break;
          case PolicyCategoryType.SearchGeneral:
            policyList = await getSearchPoliciesApi(PolicyCategoryType.SearchGeneral);
            break;
          case PolicyCategoryType.CaseMgmtGeneral:
            policyList = await getCaseMgmtPoliciesApi(PolicyCategoryType.CaseMgmtGeneral);
            break;
          case PolicyCategoryType.ActionProviders:
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.ActionProviders);
            break;
          case PolicyCategoryType.TriggerProviders:
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.TriggerProviders);
            break;
          case PolicyCategoryType.ArtifactProviders:
            policyList = await getSearchPoliciesApi(PolicyCategoryType.ArtifactProviders);
            break;
          default:
            throw Error("policy category not valid");
        }

        set((state) => ({
          policies: new Map<PolicyCategoryType, Policy[]>(state.policies).set(type, policyList)
        }));
        return policyList;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    updatePolicy: async (type: PolicyCategoryType, policy: Policy) => {
      try {
        let policyList = [] as Policy[];

        switch (type) {
          case PolicyCategoryType.WorkflowGeneral:
            await updateWorkflowPolicyApi(policy);
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.WorkflowGeneral);
            break;
          case PolicyCategoryType.SearchGeneral:
            await updateSearchPolicyApi(policy);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.SearchGeneral);
            break;
          case PolicyCategoryType.CaseMgmtGeneral:
            await updateCaseMgmtPolicyApi(policy);
            policyList = await getCaseMgmtPoliciesApi(PolicyCategoryType.CaseMgmtGeneral);
            break;
          case PolicyCategoryType.ActionProviders:
            await updateWorkflowPolicyApi(policy);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.ActionProviders);
            break;
          case PolicyCategoryType.TriggerProviders:
            await updateWorkflowPolicyApi(policy);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.TriggerProviders);
            break;
          case PolicyCategoryType.ArtifactProviders:
            await updateSearchPolicyApi(policy);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.ArtifactProviders);
            break;
          default:
            return;
        }

        set((state) => ({
          policies: new Map<PolicyCategoryType, Policy[]>(state.policies).set(type, policyList)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    createPolicy: async (type: PolicyCategoryType, policy: Policy) => {
      try {
        let policyList = [] as Policy[];

        switch (type) {
          case PolicyCategoryType.ActionProviders:
            await createWorkflowProviderPolicyApi(policy);
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.ActionProviders);
            break;
          case PolicyCategoryType.TriggerProviders:
            await createWorkflowProviderPolicyApi(policy);
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.TriggerProviders);
            break;
          case PolicyCategoryType.ArtifactProviders:
            await createSearchArtifactProviderPolicyApi(policy);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.ArtifactProviders);
            break;
          default:
            return;
        }
        set((state) => ({
          policies: new Map<PolicyCategoryType, Policy[]>(state.policies).set(type, policyList)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
    deletePolicy: async(type: PolicyCategoryType, policyId: string)  => {
      try {
        let policyList = [] as Policy[];
        switch (type) {
          case PolicyCategoryType.ActionProviders:
            await deleteWorkflowProviderPolicyApi(policyId);
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.ActionProviders);
            break;
          case PolicyCategoryType.TriggerProviders:
            await deleteWorkflowProviderPolicyApi(policyId);
            policyList = await getWorkflowPoliciesApi(PolicyCategoryType.TriggerProviders);
            break;
          case PolicyCategoryType.ArtifactProviders:
            await deleteSearchArtifactProviderPolicyApi(policyId);
            policyList = await getSearchPoliciesApi(PolicyCategoryType.ArtifactProviders);
            break;
          default:
            return;
        }
        set((state) => ({
          policies: new Map<PolicyCategoryType, Policy[]>(state.policies).set(type, policyList)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getPolicy: async (type: PolicyCategoryType, name: string, refresh?: boolean) =>{
      try {
        let policyList = get().policies.get(type)
        if (policyList && policyList?.length && !refresh) {
          return policyList.find((p) => p.name == name)
        } else if(policyList) {
          switch (type) {
            case PolicyCategoryType.WorkflowGeneral:
              policyList = await getWorkflowPoliciesApi(PolicyCategoryType.WorkflowGeneral, name);
              break;
            case PolicyCategoryType.SearchGeneral:
              policyList = await getSearchPoliciesApi(PolicyCategoryType.SearchGeneral, name);
              break;
            case PolicyCategoryType.CaseMgmtGeneral:
              policyList = await getCaseMgmtPoliciesApi(PolicyCategoryType.CaseMgmtGeneral, name);
              break;
            case PolicyCategoryType.ActionProviders:
              policyList = await getWorkflowPoliciesApi(PolicyCategoryType.ActionProviders, name);
              break;
            case PolicyCategoryType.TriggerProviders:
              policyList = await getWorkflowPoliciesApi(PolicyCategoryType.TriggerProviders, name);
              break;
            case PolicyCategoryType.ArtifactProviders:
              policyList = await getSearchPoliciesApi(PolicyCategoryType.ArtifactProviders, name);
              break;
          }
          return policyList[0];
        }
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }          
    },
  }),
  { name: "PolicyStore" })
);