import { UserOutlined, MoonOutlined, SunOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  ColorPicker,
  Dropdown,
  Layout,
  MenuProps,
  Modal as AntModal,
  Popover,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
  theme,
  Spin,
} from "antd";
import type { Color } from "antd/es/color-picker";

import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "utility/notification";

import { commonIcons, managedmodeIcons, menuIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
const { Text } = Typography;

import { auth } from "api";
import SearchBar from "components/SearchBar";
import {
  useDeveloperStore,
  useOrganizationStore,
  useProfileStore,
  useSearchStore,
  useSettingsStore,
  useSidebarStore,
} from "store";
import { useTokenStore } from "store/token";
import { TokenUtils } from "types";
import { getTagColor, getUserDetailsFromJWT } from "utility";
import { Fader } from "components/Fader";
import { TextWithIcon } from "components/TextWithIcon";
import SearchableTags from "components/SearchableTags";

const { Header } = Layout;

export interface CustomHeaderProps {
  showSearch: boolean;
}

const CustomHeader = (props: CustomHeaderProps) => {
  const jwt = useTokenStore((state) => state.token);
  const setColor = useSettingsStore((state) => state.setPrimaryColor);
  const setDarkMode = useSettingsStore((state) => state.setDarkMode);
  const profileLoading = useProfileStore((state) => state.loading);

  const [tenantId, setTenantId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [showTags, setShowTags] = useState<boolean>(false);
  const activateContext = useOrganizationStore(
    (state) => state.activateContext
  );

  const lightModePrimaryColor = useSettingsStore(
    (state) => state.lightModePrimaryColor
  );
  const darkModePrimaryColor = useSettingsStore(
    (state) => state.darkModePrimaryColor
  );
  const [tokenStatus, setTokenStatus] = useState(
    TokenUtils.getTokenStatus(jwt)
  );

  const debouncedColorPicker = useMemo(() => {
    return debounce(
      (c: Color | string) =>
        setColor(typeof c === "string" ? c : c.toHexString()),
      300
    );
  }, []);

  useEffect(() => {
    return () => {
      debouncedColorPicker.cancel();
    };
  });

  const { token } = theme.useToken();

  const navigate = useNavigate();
  const setToken = useTokenStore((state) => state.setToken);
  const [profileImageUrl, setProfileImageUrl] = useState<string>("");
  const [sidebarCollapseHover, setSidebarCollapseHover] = useState(false);

  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));

  const { mspEnabled, tags, context, setContextRequest } = useOrganizationStore(
    (state) => ({
      mspEnabled: state.mspEnabled,
      tags: state.tags,
      context: state.context,
      setContextRequest: state.setContextRequest,
    })
  );

  const { getProfile, profile } = useProfileStore((state) => ({
    getProfile: state.getProfile,
    profile: state.profile,
  }));

  const getDownloadUrl = useDeveloperStore((state) => state.getDownloadUrl);
  useEffect(() => {
    if (profile?.image) {
      getDownloadUrl(profile.image)
        .then((content) => setProfileImageUrl(content.url))
        .catch(() =>
          notification.error({
            message: "Failed to get profile image",
            duration: 6,
          })
        );
    } else {
      if (profile?.firstName && profile?.lastName) {
        const imageUrl = `https://ui-avatars.com/api/?name=${profile.firstName}+${profile.lastName}`;
        setProfileImageUrl(imageUrl);
      }
    }
  }, [profile]);

  const { searchQuery, setSearchQuery } = useSearchStore((state) => ({
    searchQuery: state.searchQuery,
    setSearchQuery: state.setSearchQuery,
  }));

  const { changeSidebarCollapse } = useSidebarStore((state) => ({
    changeSidebarCollapse: state.changeSidebarCollapse,
    sidebarCollapsed: state.sidebarCollapsed,
  }));

  const loadProfile = async () => {
    try {
      await getProfile();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const status = TokenUtils.getTokenStatus(jwt);
    setTokenStatus(status);
    const { tenantId, userId, context } = getUserDetailsFromJWT();
    setTenantId(tenantId);
    setUserId(userId);
    if (context) activateContext(context);
  }, [token]);

  useEffect(() => {
    if (!profile?.email) {
      loadProfile();
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  const logoutHelper = () => {
    localStorage.clear();
    setToken(null);
    auth.logout({ returnTo: process.env.REACT_APP_SIGNIN_URI });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: tokenStatus.isSso ? (
        <Button type="link" disabled>
          {tokenStatus.subject}
        </Button>
      ) : (
        <Button
          type="link"
          onClick={() => navigate("/profile")}
          style={{ color: lightMode ? "" : token.colorText }}
        >
          Profile
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          onClick={() => logoutHelper()}
          style={{ color: lightMode ? "" : token.colorText }}
        >
          Log Out
        </Button>
      ),
    },
  ];

  return (
    <Header
      style={{
        overflow: "hidden",
        display: "flex",
        padding: 0,
        position: "sticky",
        zIndex: 1,
        width: "100%",
        top: 0,
        right: 0,
        justifyContent: "space-between",
        justifyItems: "center",
        alignItems: "center",
        backgroundColor: token.colorBgContainer,
      }}
    >
      <div
        onMouseOver={() => setSidebarCollapseHover(true)}
        onMouseOut={() => setSidebarCollapseHover(false)}
      >
        <SvgIcon
          onClick={changeSidebarCollapse}
          component={
            sidebarCollapseHover
              ? menuIcons.menuCollapseExpandHoverIcon
              : menuIcons.menuCollapseExpandIcon
          }
        />
      </div>

      <div
        style={{
          minWidth: "500px",
          width: "45%",
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {props.showSearch && <SearchBar inHeader={true} />}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          columnGap: token.marginSM,
        }}
      >
        {mspEnabled && context != null && (
          <div>
            <Tag color={getTagColor(context)}>
              {tags?.find((tag) => tag.id == context)?.value}
            </Tag>
          </div>
        )}
        {mspEnabled && (
          <Tooltip
            color={token.colorPrimary}
            title={tags?.find((tag) => tag.id == context)?.value}
            placement={"bottom"}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => setShowTags(true)}
              icon={<SvgIcon component={commonIcons.tagsFilterIcon} />}
            />
          </Tooltip>
        )}
        <ColorPicker
          value={lightMode ? lightModePrimaryColor : darkModePrimaryColor}
          onChange={debouncedColorPicker}
          panelRender={(panel) => (
            <Space direction="vertical">
              {panel}
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => setColor(lightMode ? "#6B0BEA" : "#7f7f7f")}
              >
                Restore To Default
              </Button>
            </Space>
          )}
        />
        <Switch
          value={!lightMode}
          onChange={(checked) => setDarkMode(checked)}
          checkedChildren={<MoonOutlined />}
          unCheckedChildren={<SunOutlined />}
        />
        {/*  {(
          <BellOutlined style={{ fontSize: token.fontSizeHeading3 }} />
        )} */}
        {!tokenStatus.isSso && (
          <Avatar
            size={44}
            src={profileImageUrl}
            icon={profileImageUrl ? <UserOutlined /> : undefined}
          />
        )}

        {!tokenStatus.isSso && (
          <Spin spinning={profileLoading}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Text>Tenant Id: </Text>
                    <Text style={{ fontSize: token.fontSizeSM }}>
                      {tenantId}
                    </Text>
                    <Fader text={tenantId} onClickText="copied"></Fader>
                  </div>
                }
              >
                <Text>
                  {profile.firstName || profile.lastName
                    ? profile.firstName + " " + profile.lastName
                    : "N/A"}
                </Text>
              </Popover>
              <Text style={{ fontSize: token.fontSizeSM }}>
                {profile.title ? profile.title : "N/A"}
              </Text>
            </div>
          </Spin>
        )}
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            style={{ margin: token.margin }}
            icon={<SvgIcon component={commonIcons.moreOutlinedIcon} />}
            ghost
          />
        </Dropdown>
      </div>
      {showTags && (
        <AntModal
          title={
            <TextWithIcon
              icon={managedmodeIcons.managedModeTags}
              iconSize={18}
              text={"Tags"}
            />
          }
          open={showTags}
          onCancel={() => setShowTags(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <SearchableTags
            tags={tags}
            defaultTag={context as string}
            selectedTags={[context as string]}
            onSelect={(tag) => {
              setContextRequest(tag.id);
              setShowTags(false);
              notification.success({
                message: `Selected "${tag.value}" tag`,
                duration: 6,
              });
            }}
          />
        </AntModal>
      )}
    </Header>
  );
};

export default CustomHeader;
