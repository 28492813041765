import {
  Button,
  Empty,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";

import { FC, useEffect, useState } from "react";
import { useOrganizationStore } from "store";

import { usePolicyStore } from "store/policy";
import { Policy, PolicyDataType, PolicyCategoryType } from "types";

const { Text } = Typography;

export interface PoliciesTabProps {
  type: PolicyCategoryType;
}

export const PoliciesTab: FC <PoliciesTabProps> = (props) => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [formDisabled, setFormDisabled] = useState(true);

  const context = useOrganizationStore((state) => state.context);

  const {
    policies,
    getPolicies,
    updatePolicy,
  } = usePolicyStore((state) => ({
    policies: state.policies.get(props.type),
    getPolicies: state.getPolicies,
    updatePolicy: state.updatePolicy,
  }));

  const loadPolicies = async () => {
    try {
      setLoader(true);
      await getPolicies(props.type);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadPolicies();
  }, [context]);

  const onSubmit = async (values: any) => {
    try {
      setLoader(true);
        policies && 
        (await Promise.all(
          policies.map(async(p) => {
            if (p.value.data != values[p.name]) {
              p.value.data = values[p.name];
              await updatePolicy(props.type, p);
            }
          })));
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Spin spinning={loader}>
      <Form
        style={{ width: "75%" }}
        form={form}
        name="policiesForm"
        autoComplete="off"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
      >
        {policies?.sort((a, b) => b.value.type.localeCompare(a.value.type) ).filter((policy) => !policy.hidden).filter( (policy) => (`${policy.tenantID}_tag` == context)).map((policy: Policy) => (
          <Form.Item
            key={policy.id}
            name={policy.name}
            label={policy.displayName}
            initialValue={policy.value.data}
            rules={[
              {
                validator: (_, value) => {
                  if (policy.value.type == PolicyDataType.Numeric) {
                    if(policy.value.min && value < policy.value.min) {
                      return Promise.reject("Min limit is " + policy.value.min);
                    }
                    if(policy.value.max && value > policy.value.max) {
                      return  Promise.reject("Max limit is " + policy.value.max);
                  }
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            {policy.value.type === PolicyDataType.Boolean 
              && 
              <Switch
                disabled={formDisabled || !policy.editable}
                defaultChecked={policy.value.data === "true"}
                onChange={(checked) => {
                  form.setFieldsValue({
                    [`${policy.name}`]: checked,
                  });
                }}
              />
            }
            {policy.value.type === PolicyDataType.String 
              &&
              <Input disabled={formDisabled || !policy.editable} style={{ width: "200px"}} /> 
            }
            {policy.value.type === PolicyDataType.Numeric 
              &&
              <InputNumber disabled={formDisabled || !policy.editable} style={{ width: "80px" }} /> 
            }
            {policy.value.type === PolicyDataType.Map 
              &&
              <Table
                dataSource={Object.entries(policy.value.data).map(([k,v]) => ({
                  key: k,
                  value: v
                }))}
                columns={[
                  {
                    title: <Text>{policy.value.keyName}</Text>,
                    dataIndex: 'key',
                    key: 'key',
                    render: (key) => key
                  },
                  {
                    title: <Text>{policy.value.valueName}</Text>,
                    dataIndex: 'value',
                    key: 'value',
                    render: (value, record) => {
                      return (
                        formDisabled 
                          ? 
                            <Text disabled>{value}</Text>
                          :
                            <InputNumber 
                              value={value} 
                              onChange={(val) => {
                                policy.value.data[record.key] = val;
                                form.setFieldsValue({
                                  [`${policy.name}`]: policy.value.data,
                                });
                              }}
                              style={{ width: "80px" }} 
                            /> 
                      );
                    },
                  }]}
                rowKey="key"
                pagination={false}
              />
            }
          </Form.Item>
        ))}
      </Form>
      {policies?.length 
        ?
        formDisabled 
          ?
            <Button type="primary" onClick={() => setFormDisabled(false)}>
              Edit
            </Button>
          :
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => 
                  form
                    .validateFields()
                    .then(onSubmit)
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setFormDisabled(true);
                    })
                }
              >
                Save
              </Button>
              <Button onClick={() => setFormDisabled(true)}>
                Cancel
              </Button>
            </Space>
        :
          <Empty />
      }
    </Spin>
  );
}