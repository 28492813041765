import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { ImageField } from "components/EntityEditor";
import { useEffect, useState } from "react";
import { useDeveloperStore, useProfileStore } from "store";
import { notification } from "utility/notification";
import { userRegions } from "types";
import userImage from "assets/images/user.png";

const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

export const ProfileCard = () => {
  const getLabel = (key: string): string => {
    switch (key) {
      case "business":
        return "Business Name";
      case "email":
        return "Email";
      case "phone":
        return "Phone";
      case "title":
        return "Title";
      case "region":
        return "Region";
      case "locale":
        return "Locale";
      case "image":
        return "Image";
      case "firstName":
        return "First Name";
      case "lastName":
        return "Last Name";
      default:
        return key;
    }
  };

  const getDisabled = (key: string): boolean => {
    switch (key) {
      case "email":
        return true;
      case "phone":
        return true;
      default:
        return false;
    }
  };

  const [profileImage, setProfileImage] = useState<JSX.Element>(
    <img
      src={userImage}
      style={{
        width: "100%",
        borderRadius: "50%",
        objectFit: "cover",
      }}
      alt="profile"
    />
  );

  const { profile, updateProfile } = useProfileStore((state) => ({
    updateProfile: state.updateProfile,
    profile: state.profile,
  }));

  const getDownloadUrl = useDeveloperStore((state) => state.getDownloadUrl);

  useEffect(() => {
    if (profile?.image) {
      getDownloadUrl(profile.image).then((url) => {
        setProfileImage(
          <img
            src={url.url}
            style={{
              width: "100%",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            alt="profile"
          />
        );
      });
    }
  }, [profile]);

  const onFinish = async (data: any) => {
    Object.keys(data)?.forEach((ele: any) => {
      if (data[ele] === undefined || data[ele] === null) delete data[ele];
    });
    try {
      setLoading(true);
      await updateProfile(data);
      notification.success({
        message: "Profile data updated successfully",
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: "Failed to update profile data",
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const save = () => {
    form.submit();
    setEdit(false);
  };

  const cancel = () => {
    setEdit(false);
  };

  const [loader, setLoading] = useState(false);
  const buttons = () =>
    edit ? (
      <Space>
        <Button type="primary" onClick={() => save()}>
          Save
        </Button>
        <Button type="default" onClick={() => cancel()}>
          Cancel
        </Button>
      </Space>
    ) : (
      <Button type="primary" onClick={() => setEdit(true)}>
        Edit
      </Button>
    );

  return (
    <Card
      key={"profile-basic-information-card"}
      title="Basic Information"
      extra={buttons()}
      style={{ width: "100%" }}
    >
      <Spin spinning={loader}>
        <div
          id="profile-basic-information-container"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            id="picture-container"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ImageField
              identifier="image"
              label="Profile picture"
              max={1}
              path={[]}
              value={profile?.image || ""}
              enabled={true}
              hideLabel={true}
              externalUploadButton={profileImage}
              onChange={async (_: string, value: string) => {
                await updateProfile({ image: value });
              }}
            ></ImageField>
            <Text strong>
              {profile?.firstName} {profile?.lastName}
            </Text>
            <Text>{profile?.title}</Text>
            <Text>{profile?.region}</Text>
          </div>
          <div id="form-container" style={{ flex: 2 }}>
            <Form
              name="profile"
              form={form}
              onFinish={onFinish}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
            >
              {Object.entries(profile)
                .filter((x) => x[0] != "locale")
                .map(([key, entry]) => {
                  return key != "image" ? (
                    <Item
                      key={key}
                      name={key}
                      label={<Text strong>{getLabel(key)}</Text>}
                      initialValue={entry}
                    >
                      {edit ? (
                        key == "region" ? (
                          <Select
                            showAction={["focus", "click"]}
                            className="authSelect"
                            placeholder="Select region of residence"
                            defaultValue={entry}
                            allowClear
                          >
                            {userRegions.map((region) => (
                              <Option key={region} value={region}>
                                {region}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Input disabled={getDisabled(key)} value={entry} />
                        )
                      ) : (
                        <Text>{entry}</Text>
                      )}
                    </Item>
                  ) : (
                    <Item
                      key={"image"}
                      name={"image"}
                      style={{ display: "none" }}
                      initialValue={entry}
                    >
                      <Input type="hidden" />
                    </Item>
                  );
                })}
            </Form>
          </div>
        </div>
      </Spin>
    </Card>
  );
};
