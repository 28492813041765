import { FC } from "react";
import { Form, Input, Space, theme, Select, Row, Col } from "antd";

import Modal from "components/Modal";

import { CaseSeverityMap, CaseSeverity, CasePriority, CasePriorityMap, CaseCategoriesMap, CaseCategories } from "types";
import { FieldLabel } from "components/FieldLabel";
import { useRbacStore } from "store";
import { getUserDetailsFromJWT } from "utility";

const { TextArea } = Input;

export type CaseCreateModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
};

const CaseCreateModal: FC<CaseCreateModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const users = useRbacStore((state) => state.users);

  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };

  return (
    <Modal
      title={"New Case"}
      open={open}
      footerName="Create"
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then((values) => {
            onClose();
            onSubmit(values);
          })
          .catch((err) => {
            console.log(err);
          })
      }
    >
      <Form
        form={form}
        name="saveCaseForm"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical" 
      >
        <Space size={token.marginXXS} direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} />}
            initialValue={""}
            rules={getRules("Name", true)}
            validateTrigger="onSubmit"
          >
            <Input placeholder="Enter case name"  maxLength={512}/>
          </Form.Item>
          <Form.Item
            name="description"
            label={<FieldLabel label={"Description"} />}
            initialValue={""}
            validateTrigger="onSubmit"
          >
            <TextArea placeholder="Enter case description" maxLength={4096}/>
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="severity"
                label={<FieldLabel label={"Severity"} />}
                initialValue={CaseSeverity.Low}
                rules={getRules("Severity", true)}
                validateTrigger="onSubmit"
              >
                <Select
                  showAction={["focus", "click"]}
                  options={Array.from(CaseSeverityMap, ([k, v]) => ({
                    label: v,
                    value: k,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="priority"
                label={<FieldLabel label={"Priority"} />}
                initialValue={CasePriority.P3}
                rules={getRules("Priority", true)}
                validateTrigger="onSubmit"
              >
                <Select
                  showAction={["focus", "click"]}
                  options={Array.from(CasePriorityMap, ([k, v]) => ({
                    label: v,
                    value: k,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="category"
                label={<FieldLabel label={"Category"} />}
                rules={getRules("category", true)}
                validateTrigger="onSubmit"
                initialValue={CaseCategories[0]}
              >
                <Select
                  showAction={["focus", "click"]}
                  options={Array.from(CaseCategoriesMap, ([k, v]) => ({
                    label: v,
                    value: k,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="assigneeID"
                label={<FieldLabel label={"Assignee"} />}
                initialValue={getUserDetailsFromJWT()?.userId}
                rules={getRules("assigneeID", true)}
                validateTrigger="onSubmit"
              >
                <Select
                  showAction={["focus", "click"]}
                  options={Object.entries(users).map(([id, u]) => ({
                    label: u.firstName + ' ' + u.lastName,
                    value: id,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item 
            name="labels"
            label={<FieldLabel label={"Labels"}/>}
            validateTrigger="onSubmit"
          >
            <Select
              showAction={["focus", "click"]}
              placeholder={`Enter labels`}
              allowClear
              mode="tags"
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CaseCreateModal;
