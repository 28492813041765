import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Spin,
  notification,
  Typography,
} from "antd";

import { FormInstance, Rule } from "antd/lib/form";
import { useAppStore, useSearchStore } from "store";
import { Search, SearchTabType, SearchRunStatus } from "types";
import SearchBar from "components/SearchBar";
import { SaveSearch } from "./SaveSearch";

const { Text } = Typography;

enum SearchAddState {
  None, // Nothing has been clicked
  Add, // Add has been clicked
  Cancel, // Cancel has been clicked
  Active, // Search is active
  Fail, // Search has failed, no results
  Results, // Search has been run and results are shown
  Saved, // Saved
}

export interface SearchFormInputProps {
  form: FormInstance;
  search: string | undefined;
  name: string;
  label: string;
  validation?: Rule[];
}

export const SearchFormInput = (props: SearchFormInputProps) => {
  const searchItems = useSearchStore((state) => state.searchItemsMap);
  const totalAppsCount = useAppStore((state) => state.totalAppsCount);

  const [searches, setSearches] = useState([] as Search[]);
  const [, setLoading] = useState(false);
  const [searchAddState, setSearchAddState] = useState(SearchAddState.None);
  const [addSearchEnabled, setAddSearchEnabled] = useState(false);
  const getSearchItems = useSearchStore((state) => state.getSearchItems);

  const { getSearchRun, runSearchX } = useSearchStore((state) => ({
    getSearchRun: state.getSearchRun,
    runSearchX: state.runSearchX,
  }));

  useEffect(() => {
    setAddSearchEnabled(totalAppsCount > 0);
  }, [totalAppsCount]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      try {
        setLoading(true);
        await getSearchItems(SearchTabType.SavedSearches, 1, 10000);
      } catch (error) {
        notification.error({
          message: `Failed to fetch search items, ${JSON.stringify(error)}`,
          duration: 6,
        });
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    setSearches(searchItems.get(SearchTabType.SavedSearches) || []);
  }, [searchItems]);

  const onSaved = (s: Search | undefined) => {
    /** Set form search value to this */
    if (s) {
      props.form.setFieldsValue({ search: s.id });
    } else {
      notification.error({
        message: "Failed to select saved search",
        duration: 6,
      });
    }
  };

  const onQuery = async (query: string) => {
    try {
      setSearchAddState(SearchAddState.Active);
      const activeSearchId = await runSearchX(query);
      let searchRun = await getSearchRun(activeSearchId);
      let nTries = 0;
      if (activeSearchId) {
        /** Keep polling till results show up */
        while (searchRun?.status == SearchRunStatus.Running && nTries < 60) {
          nTries++;
          searchRun = await getSearchRun(activeSearchId);
          await new Promise((r) => setTimeout(r, 1000));
        }
        if (searchRun?.status == SearchRunStatus.Completed) {
          setSearchAddState(SearchAddState.Results);
        } else if (searchRun?.status == SearchRunStatus.Failed) {
          throw new Error("Search failed");
        } else if (nTries >= 60) {
          throw new Error("Search timed out");
        }
      } else throw new Error("Failed to run search");
    } catch (error) {
      setSearchAddState(SearchAddState.Fail);
      let message = "search failed";
      if (error instanceof Error) {
        message = error.message;
      }
      notification.error({
        message,
        duration: 6,
      });
    }
  };
  return (
    <>
      <Form.Item
        id={`search-input-${props.name}`}
        label={props.label}
        name={props.name}
        rules={addSearchEnabled ? props.validation : []}
        initialValue={props.search}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Select
              disabled={!addSearchEnabled}
              onSelect={(value) => props.form.setFieldsValue({ search: value })}
            >
              {searches.map((search) => (
                <Select.Option key={search.id} value={search.id}>
                  {search.name}
                </Select.Option>
              ))}
            </Select>
            {searchAddState == SearchAddState.Add && (
              <Button onClick={() => setSearchAddState(SearchAddState.None)}>
                Cancel
              </Button>
            )}
            {searchAddState == SearchAddState.None && (
              <Button
                disabled={!addSearchEnabled}
                type="default"
                onClick={() => setSearchAddState(SearchAddState.Add)}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      </Form.Item>
      <Row>
        <Col span={8} />
        <Col span={16}>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            {searchAddState == SearchAddState.Add && (
              <SearchBar inHeader={false} onQuery={onQuery} />
            )}
            {searchAddState == SearchAddState.Active && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Spin spinning={true}>
                  <Text>Validating search !!!</Text>
                </Spin>
                <Button
                  onClick={() => setSearchAddState(SearchAddState.Cancel)}
                >
                  Cancel
                </Button>
              </div>
            )}
            {searchAddState == SearchAddState.Cancel && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Text type="warning">Search cancelled !!!</Text>
                <Button onClick={() => setSearchAddState(SearchAddState.None)}>
                  OK
                </Button>
              </div>
            )}

            {searchAddState == SearchAddState.Fail && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Text type="danger">Search failed !!!</Text>
                <Button onClick={() => setSearchAddState(SearchAddState.None)}>
                  OK
                </Button>
              </div>
            )}
            {searchAddState == SearchAddState.Results && (
              <SaveSearch
                onClose={() => setSearchAddState(SearchAddState.None)}
                onSaved={onSaved}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
