import { useEffect, useState } from "react";


import {
  Divider,
  Space,
  Tabs,
  Typography,
  theme,
} from "antd";

import {
  useMetricStore,
  useSettingsStore,
  useWorkflowStore,
  useWorkflowStepsStore,
} from "store";

import { MetricLayoutIdentifier, HyprFlowTabType, PolicyCategoryType } from "types";
import ActivityOverview from "../../components/ActivityOverview";

import PageWrapper from "../../components/PageWrapper";
import WorkflowsTab from "./workflowsTab";

import { workflowIcons, commonIcons } from "assets/icons";
import { KpiPageHighlightGrid } from "components/Metrics/KpiPageHighlightGrid";
import { SvgIcon } from "components/SvgIcon";
import TemplatesTab from "./templatesTab";
import { usePolicyStore } from "store/policy";

const { Title, Text } = Typography;

export const HyprFlows = () => {
  const { token } = theme.useToken();
  const { activeTab, setActiveTab } = useWorkflowStore(
    (state: any) => ({
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
    })
  );

  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const metrics = useMetricStore((state) => state.metrics);
  const fetchMetricsForLayout = useMetricStore((state) => state.fetchMetricsForLayout);
  const getPolicies = usePolicyStore((state) => state.getPolicies);

 
  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutHyprflows);
      getPolicies(PolicyCategoryType.ActionProviders);
      getPolicies(PolicyCategoryType.TriggerProviders);
      getPolicies(PolicyCategoryType.ArtifactProviders);
    };
    asyncUseEffect();
  }, []);

  return (
    <PageWrapper
      // feedback={<ActivityOverview pageSize={9} resizable={true} />}
      highlights={
        <KpiPageHighlightGrid key="workflows" metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutHyprflows)}  />
      }
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <Title level={3}>HyprFlows</Title>
          {useDivider && <Divider></Divider>}
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key as HyprFlowTabType)}
            items={[
              {
                label: (
                  <Space size={token.marginXXS}>
                    <SvgIcon component={workflowIcons.workflowShortIcon} />
                    <Text>Workflows</Text>
                  </Space>
                ),
                key: HyprFlowTabType.Workflows,
                children: (
                  <WorkflowsTab />
                ),
              },
              {
                label: (
                  <Space size={token.marginXXS}>
                    <SvgIcon component={commonIcons.templateIcon} />
                    <Text>Templates</Text>
                  </Space>
                ),
                key: HyprFlowTabType.WorkflowTemplates,
                children: (
                  <TemplatesTab />
                ),
              },
            ]}
          />
        </div>
      }
    ></PageWrapper>
  );
};
