import { MailOutlined, UserAddOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  theme,
  Typography,
} from "antd";

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "store";
import { userRegions } from "types";
import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import { generateRandomString } from "../../utility";

import { HLink } from "components/Link";
import { notification } from "utility/notification";
import styles from "./SignUp.module.scss";
import { HyprEdgePhoneInput } from "components/HyprEdgePhoneInput";

const { Text } = Typography;

const SignUp: FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loader, setLoader] = useState<boolean>(false);
  const [tosAgree, setTosAgree] = useState<boolean>(false);
  const initiateSignUp = useAuthStore((state) => state.initiateSignUp);

  const onSubmit = async (value: any) => {
    setLoader(true);
    try {
      await initiateSignUp(
        value.business,
        value.email,
        value.firstName,
        value.lastName,
        `+${value.phone}`,
        value.region,
        value.code,
        generateRandomString()
      );
      navigate("/mobile-otp-verification");
    } catch (error: any) {
      if (error.message) {
        notification.error({
          message: error.message,
          duration: 6,
        });
      }
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Spin spinning={loader}>
      <SignInSignUpWrapper>
        <div
          className="verticalAlignDiv"
          style={{ width: "75%", maxWidth: "400px" }}
        >
          <div className={styles.formWrap}>
            <div className={styles.FormDiv}>
              <Form
                form={form}
                name="signUpForm"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="business"
                  label="Business Name"
                  rules={[
                    { required: true, message: "Business name is required!" },
                  ]}
                >
                  <Input
                    className="authInput"
                    placeholder="Enter your business name"
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required!" },
                    () => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
                          return Promise.reject("Enter a valid email");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="authInput"
                    placeholder="Enter your email address"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      name="firstName"
                      label="First Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      name="lastName"
                      label="Last Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="phone"
                  label="Phone Number."
                  rules={[
                    { required: true, message: "Phone No. is required!" },
                  ]}
                >
                  <HyprEdgePhoneInput country={"us"} enableSearch={true} />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="region"
                  label="Primary Deployment Region"
                  rules={[{ required: true, message: "Region is required!" }]}
                >
                  <Select
                    showAction={["focus", "click"]}
                    className="authSelect"
                    placeholder="Select primary deployment region"
                    allowClear
                    options={userRegions.map((region) => ({
                      label: region,
                      value: region,
                    }))}
                  ></Select>
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="code"
                  label="Registration Code"
                  rules={[
                    {
                      required: true,
                      message: "6 character regustration code is required!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve("");
                        }
                        if (!/^[A-Z0-9]{6}$/.test(value)) {
                          return Promise.reject(
                            "Enter your 6 character registration code"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="authInput"
                    placeholder="Enter your 6 character registration code"
                    prefix={<UserAddOutlined />}
                  />
                </Form.Item>
                <Text>{"Dont have a registration code? "}</Text>
                <HLink
                  className="textLink"
                  href="mailto:contact@hypredge.com?subject=Request for registration code"
                  text={"Request one here"}
                />
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="agree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject("Need to agree to the terms of use"),
                    },
                  ]}
                >
                  <Checkbox
                    onChange={(event) => setTosAgree(event.target.checked)}
                  >
                    I agree with the
                    <a
                      href="https://hypredge.com/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms Of Use
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={!tosAgree}
                    htmlType="submit"
                    className="authbtn_100"
                    type="primary"
                    onClick={() =>
                      form
                        .validateFields()
                        .then(onSubmit)
                        .catch((err) => {
                          console.log(err);
                        })
                    }
                  >
                    Sign Up
                  </Button>
                </Form.Item>
              </Form>
              {/* <div className={styles.or}>OR</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Button type="primary" icon={<GoogleCircleFilled />}>
                  Google
                </Button>
                <Button type="primary" icon={<GithubFilled />}>
                  GitHub
                </Button>
                <Button type="primary" icon={<WindowsFilled />}>
                  Microsoft
                </Button>
              </div>
 */}
              {/* <div>
                <div
                  onClick={() => {
                    localStorage.setItem("isThirdParty", "true");

                    auth.authorize({
                      connection: "google-oauth2",
                      scope: "openid email profile",
                      responseType: "token id_token",
                      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
                    });
                  }}
                  className={styles.googleconnect}
                >
                  {" "}
                  <span>Connect with Google</span>
                </div>
                <div
                  onClick={() => {
                    localStorage.setItem("isThirdParty", "true");
                    auth.authorize({
                      connection: "windowslive",
                      scope: "openid profile",
                      responseType: "token id_token",
                      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
                    });
                  }}
                  className={styles.microConnect}
                >
                  {" "}
                  <span>Connect with Microsoft</span>
                </div>
              </div> */}
              <Text>{"Already have an account ? "}</Text>
              <HLink
                className="textLink"
                onClick={() => navigate(`/signin`)}
                text={"Sign In here"}
              />
            </div>
          </div>
        </div>
      </SignInSignUpWrapper>
    </Spin>
  );
};

export default SignUp;
