import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import { notification } from "utility/notification";

import { useEffect, useState } from "react";
import {
  useAppStore,
  useGoalStore,
  useRbacStore,
  useWorkflowStore,
} from "store";
import { Goal } from "types";
import { MeasurementEditor } from "./MeasurementEditor";
import { SearchFormInput } from "./SearchFormInput";

export interface GoalsEditProps {
  goal?: Goal;
  isAdd: boolean;
  onClose: () => Promise<void>;
}

export const GoalsEdit = (props: GoalsEditProps) => {
  const [, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(false);
  const [haveApps, setHaveApps] = useState(false);

  const users = useRbacStore((state) => state.users);
  const workflows = useWorkflowStore((state) => state.workflows);

  const updateGoal = useGoalStore((state) => state.updateGoal);
  const addGoal = useGoalStore((state) => state.addGoal);
  const [goalOwner, setGoalOwner] = useState<string | undefined>(undefined);
  const totalAppsCount = useAppStore((state) => state.totalAppsCount);

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }

      if (props.isAdd) {
        await addGoal(values);
      } else {
        if (props.goal) {
          const updateData = {
            ...props.goal,
            ...values,
          };
          await updateGoal(props.goal?.id, updateData);
        }
      }
    } catch (error) {
      if (!hasValidationError) {
        console.log("Failed to create goal", error);
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } goal. Check console for more details`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  useEffect(() => {
    setHaveApps(totalAppsCount > 0);
  }, [totalAppsCount]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setModalState(true);
      try {
        setLoading(true);
        if (props.goal) {
          const fields = {
            title: props.goal.title,
            description: props.goal.description,
            owner: props.goal.owner,
            members: props.goal.members,
            workflows: props.goal.workflows,
            search: props.goal.search,
            enabled: props.goal.enabled,
            target: props.goal.target,
            measurement: props.goal.measurement,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
      } catch (error) {
        notification.error({
          message: `Failed to fetch goals, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    };
    asyncUseEffect();
  }, []);

  const onClose = async () => {
    setModalState(false);
    await props.onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      onOk={handleSubmit}
      title={"Goals"}
      open={modalState}
      width={1024}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 8, offset: 0 }}
          wrapperCol={{ span: 16, offset: 0 }}
        >
          <Form.Item
            id={"title"}
            label={"Title"}
            name={"title"}
            initialValue={props.goal?.title}
            rules={[{ required: true, message: "Please input goal title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            id={"description"}
            label={"Description"}
            name={"description"}
            initialValue={props.goal?.description}
            rules={[{ required: true, message: "Please input goal title!" }]}
          >
            <Input.TextArea
              rows={4}
              onChange={(e) => {
                form.setFieldsValue({ description: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item
            id={"owner"}
            label={"Owner"}
            name={"owner"}
            initialValue={props.goal?.owner}
            rules={[{ required: true, message: "Please select owner!" }]}
          >
            <Select
              onSelect={(value) => {
                setGoalOwner(value);
                form.setFieldsValue({ owner: value });
                const members = form.getFieldValue("members");
                if (members) {
                  form.setFieldsValue({
                    members: members.filter((x: string) => x != value),
                  });
                }
              }}
            >
              {Object.keys(users).map((userId) => (
                <Select.Option key={userId} value={userId}>
                  {users[userId].firstName} {users[userId].lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            id={"members"}
            label={"Members"}
            name={"members"}
            dependencies={["owner"]}
            initialValue={props.goal?.members}
          >
            <Select
              onChange={(value: string[]) =>
                form.setFieldsValue({ members: value })
              }
              mode="multiple"
            >
              {Object.keys(users)
                .filter((x) => x != goalOwner)
                .map((userId) => (
                  <Select.Option key={userId} value={userId}>
                    {users[userId].firstName} {users[userId].lastName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            id={"workflows"}
            label={"Workflows"}
            name={"workflows"}
            initialValue={props.goal?.workflows}
          >
            <Select
              onChange={(value: string[]) =>
                form.setFieldsValue({ workflows: value })
              }
              mode="multiple"
            >
              {workflows.map((workflow) => (
                <Select.Option key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            id={"measurement"}
            label={"Measurement"}
            name={"measurement"}
            initialValue={props.goal?.measurement}
            rules={[
              {
                required: true,
                message: "Please input measurement method!",
              },
            ]}
          >
            <MeasurementEditor
              measurement={form.getFieldValue("measurement")}
              target={form.getFieldValue("target")}
              onChange={(measurement, target) => {
                form.setFieldsValue({ measurement, target });
              }}
            />
          </Form.Item>
          <Form.Item
            hidden={true}
            id={"target"}
            label={"Goal Target"}
            name={"target"}
            initialValue={props.goal?.target}
            rules={[{ required: true, message: "Please input goal target!" }]}
          >
            <InputNumber style={{ width: "100%" }} type="hidden"></InputNumber>
          </Form.Item>
          <SearchFormInput
            form={form}
            label="Data Source"
            name="search"
            search={props.goal?.search}
            validation={[
              { required: true, message: "Please input data source!" },
            ]}
          />
          <Form.Item
            id={"enabled"}
            label={"Enabled"}
            name={"enabled"}
            initialValue={false}
          >
            <Switch
              disabled={!haveApps}
              onChange={(value) => form.setFieldsValue({ enabled: value })}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
