import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { ActivityStore, AuditLogStore, dateTimeFormatOptions, PageInfo } from "types";

import { getActivitiesApi } from "api";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";

export const useActivityStore = create<ActivityStore>()(
  devtools((set, get) => ({
    activities: [],
    getActivities: async (pageNumber, pageSize, group) => {
      try {

        const auditLogFilters = [`equals(group,'${group}')`];
        const [activities, totalCount] = await getActivitiesApi(pageNumber, pageSize, auditLogFilters);
        
        activities.forEach( (activity) => {
          activity.localTime = new Date(activity.localTime).toLocaleTimeString(undefined, dateTimeFormatOptions)
        })

        set((state) => ({
          activities: [...state.activities, ...activities]
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
  }),
  { name: "ActivityStore" })
);

export const useAuditLogStore = create<AuditLogStore>()(
  devtools((set, get) => ({
    auditLogs: [],
    totalCount: 0,
    pageInfo: {number:1, size: 12, total: 0} as PageInfo,
    filter: {} as Record<string, FilterValue | null>,
    sorter: {} as SorterResult<DataType>,
    searchTextMap: new Map<string, string>(),

    getAuditLogs: async () => {
      try {
        const pageInfo = get().pageInfo;
        const filters = get().filter;
        const searches = get().searchTextMap;
        const sorters = get().sorter;
        
        const auditLogFilters = convertToApiFilters(filters, searches)     
        const auditLogSorters = convertToApiSorters(sorters);
        //default sorting
        if (auditLogSorters.length == 0) {
          auditLogSorters.push("-localTime");
        }

        const pageNumber = pageInfo.number;
        const pageSize = pageInfo.size
        const [auditLogs, totalCount] = await getActivitiesApi(pageNumber, pageSize, auditLogFilters, auditLogSorters);
        
        set((state) => ({
          ...state,
          auditLogs: [...auditLogs],
          totalCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearAuditLogs: () => {
      set((state) => ({
        ...state,
        auditLogs: [],
        totalCount: 0,
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },

    setFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, filter : filters};
      });
      return;
    },

    setSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, sorter : sorters};
      });
      return;
    },

    setSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        searchTextMap: new Map<string, string>(searches),
      }));
    },
  }),
  { name: "AuditLogStore" })
);
