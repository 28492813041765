import { useEffect, useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Collapse,
  Empty,
  Spin,
  Typography,
  theme,
  Badge,
  Space,
  Alert,
  Switch,
  Dropdown,
  MenuProps,
  Skeleton,
  Layout,
  Tooltip,
  Popover,
  Tag,
  Flex,
  Modal,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HistoryOutlined,
  SaveOutlined,
  WarningOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { notification } from "utility/notification";
import pRetry, { FailedAttemptError } from "p-retry";
import { getStyles } from "utility/styles";

import { commonIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useOrganizationStore, useSearchStore } from "store";
import PageWrapper from "components/PageWrapper";
import SaveSearchModal from "./SaveModal";
import ResultPanel from "./ResultPanel";
import { Search, SearchRunResultMeta, SearchRunStatus, SearchTabType } from "types";
import ActionsHistory from "./ActionPanel/actionsHistory";
import ControlButton from "components/ControlButton/controlButton";
import ActionPanel from "./ActionPanel";
import { Content } from "antd/es/layout/layout";
import { getAvatarColor, getTagColor } from "utility";
import { getSearchRunIdsListFromSearchRun } from "./Utils";

const { Text } = Typography;

export const SearchRunComponent = () => {
  const { token } = theme.useToken();
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      overflow: "hidden",
    },
  })();
  const { id: searchRunId } = useParams();
  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [showActionsHistory, setShowActionsHistory] = useState<boolean>(false);
  const [showActionPanel, setShowActionPanel] = useState(false);
  const abortController = new AbortController();
  const context = useOrganizationStore((state) => state.context);
  const [appSubscriptionHoverId, setAppSubscriptionHoverId] = useState("");
  const [filteredAppSubscriptions, setFilteredAppSubscriptions] = useState<string[]>([]);
  const [tagHoverId, setTagHoverId] = useState("");
  const [filteredTags, setFilteredTags] = useState<string[]>([]);

  const headerDropDownItems: MenuProps["items"] = [
    {
      key: "actions",
      label: "Actions History",
      icon: <HistoryOutlined />,
    },
  ];
  const { mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const {
    currentSearchRun,
    currentSearchRunTags,
    searchLoader,
    showDetailedResults,
    recommendedActions,
    savedSearchPage,
    getSearchRun,
    getSearchRunRecommendedActions,
    getSearchRunActions,
    setShowDetailedResults,
    searchRunActionSelectedDatas,
    createSearch,
    cancelSearchRun,
    resetSearchState,
    getSearchItems,
  } = useSearchStore((state) => ({
    currentSearchRun: state.currentSearchRun,
    currentSearchRunTags: state.currentSearchRunTags,
    searchLoader: state.searchLoader,
    showDetailedResults: state.showDetailedResults,
    recommendedActions: state.recommendedActions,
    savedSearchPage: state.searchPage[SearchTabType.SavedSearches],
    getSearchRun: state.getSearchRun,
    getSearchRunRecommendedActions: state.getSearchRunRecommendedActions,
    getSearchRunActions: state.getSearchRunActions,
    setShowDetailedResults: state.setShowDetailedResults,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    createSearch: state.createSearch,
    cancelSearchRun: state.cancelSearchRun,
    resetSearchState: state.resetSearchState,
    getSearchItems: state.getSearchItems,
  }));

  const cancelSearch = async () => {
    if (!searchRunId) return;
    try {
      setLoader(true);
      await cancelSearchRun(searchRunId);
      notification.success({
        message: "Search cancellation triggered succesfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Unable to cancel search",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const saveSearch = async (
    name: string,
    description: string,
    selectedTags?: string[],
    tagsOnly?: boolean
  ) => {
    try {
      setLoader(true);
      const search = {
        name: name,
        description: description,
        createTags: selectedTags,
      } as Search;
      await createSearch(search, tagsOnly);
      await getSearchItems(
        SearchTabType.SavedSearches,
        savedSearchPage.number,
        savedSearchPage.size
      );
      setSaveSearchModal(false);
      setLoader(false);
      notification.success({
        message: "Search saved successfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      setLoader(false);
      notification.error({
        message: "Unable to save search",
        duration: 6,
      });
    }
  };

  const fetchRecommendedActions = async () => {
    try {
      await getSearchRunRecommendedActions(searchRunId);
    } catch (error) {
      console.log(error);
    }
  };

  const syncSearchRunInBackground = async () => {
    try {
      const sr = await getSearchRun(searchRunId);
      if (sr.status == SearchRunStatus.Running) {
        throw new Error(sr.status);
      }
    } catch (e) {
      if ((e as Error).message == SearchRunStatus.Running) {
        throw new Error(SearchRunStatus.Running);
      } else {
        //On failure of loading search run, it could be a unauthorized access, switch it to workspace home page
        navigate(`/workspace`);
      }
    }
  };

  useEffect(() => {
    if (searchRunId && searchRunId != "") {
      const asyncUseEffect = async () => {
        try {
          fetchRecommendedActions();
          pRetry(() => syncSearchRunInBackground(), {
            retries: 60,
            minTimeout: 5000,
            maxTimeout: 5000,
            signal: abortController.signal,
          }).catch((e: FailedAttemptError) => {
            console.log(
              "pretry sync search run status completed, exiting sync of status"
            );
          });
        } catch (e) {
          console.log("failed to get search run actions", e);
        }
      };
      asyncUseEffect();
    }
  }, [searchRunId, context]);

  useEffect(() => {
    resetSearchState();
    return () => {
      abortController.abort("exiting search run page");
      resetSearchState();
    };
  }, []);

  const getSubHeadingMessageForSearchResults = (): string => {
    if (
      currentSearchRun.status == SearchRunStatus.Running ||
      currentSearchRun.resultCount == undefined
    ) {
      return `searching...across apps`;
    } else {
      return (
        currentSearchRun.resultCount +
        " search results" +
        (currentSearchRun.metrics?.timeTaken
          ? ", " + currentSearchRun.metrics?.timeTaken
          : "") +
        (currentSearchRun.metrics?.appsCount
          ? ", across " + currentSearchRun.metrics?.appsCount + " apps"
          : "") +
        (currentSearchRun.metrics?.tenantsCount
          ? ", and " + currentSearchRun.metrics?.tenantsCount + " tenants"
          : "")
      );
    }
  };

  const showRunStatus = () => {
    switch (currentSearchRun?.status) {
      case SearchRunStatus.Running:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logProgressIcon}
            hexColor={"orange"}
          />
        );
      case SearchRunStatus.Completed:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logSuccessIcon}
            hexColor="green"
          />
        );
      case SearchRunStatus.Failed:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logFailedIcon}
            hexColor="red"
          />
        );
      default:
        return <></>;
    }
  };

  const getApps = () => {
    if (
      currentSearchRun.resultCount &&
      currentSearchRun.resultMetas &&
      currentSearchRun.resultMetas.length
    ) {
      const apps: Record<string, string> = {};
      currentSearchRun.resultMetas?.forEach((rm) => {
        if (rm.count) {
          apps[rm.appID] = rm.appName ;
        }
      });
      return (
        <Flex gap="4px 4px" wrap="wrap" align={"left"}>
          {currentSearchRun.resultMetas
          .sort((a, b) => (a.appName > b.appName ? -1 : 1))
          .sort((a, b) => (a.count > b.count ? -1 : 1))
          .map((rm, index) => (
            <div
              key={rm.appSubscriptionID}
              onClick={(e) => {
                if(rm.count && rm.resultStatus == SearchRunStatus.Completed) {
                  if (filteredAppSubscriptions.includes(rm.appSubscriptionID)) {
                    setFilteredAppSubscriptions(filteredAppSubscriptions.filter((x) => x != rm.appSubscriptionID));
                  } else {
                    setFilteredAppSubscriptions([...filteredAppSubscriptions, rm.appSubscriptionID]);
                  }
                }
              }}
              style={{ cursor: "pointer" }}
              onMouseOver={() => setAppSubscriptionHoverId(rm.appSubscriptionID)}
              onMouseOut={() => setAppSubscriptionHoverId("")}
            >
              <Tag
                color={
                  appSubscriptionHoverId == rm.appSubscriptionID
                    ? token.colorPrimaryBorderHover
                    : (rm.count && rm.resultStatus == SearchRunStatus.Completed) ? 
                      getAvatarColor(rm.appID): token.colorTextDisabled
                }
                icon={
                  filteredAppSubscriptions.includes(rm.appSubscriptionID) ? 
                    <CloseCircleOutlined/>
                  :
                    rm.resultStatus == SearchRunStatus.Completed ? 
                      rm.count ? <CheckCircleOutlined/> : <CloseCircleOutlined/>
                    : <WarningOutlined style={{ color: token.colorWarning }}/>
                }
              >
                {`${rm.appName}(${rm.appSubscriptionName})`}
              </Tag>
            </div>
          ))}
        </Flex>
      );
    } else {
      return <></>;
    }
  };

  const getTags = () => {
    if (!mspEnabled) {
      return <></>;
    }
    return (
      <Flex gap="4px 4px" wrap="wrap" align={"left"}>
        {currentSearchRunTags.map((tagId) => (
          <div
            key={tagId}
            onClick={(e) => {
              if (filteredTags.includes(tagId)) {
                setFilteredTags(filteredTags.filter((x) => x != tagId));
              } else {
                setFilteredTags([...filteredTags, tagId]);
              }
            }}
            style={{ cursor: "pointer" }}
            onMouseOver={() => setTagHoverId(tagId)}
            onMouseOut={() => setTagHoverId("")}
          >
            <Tag
              color={
                tagHoverId == tagId
                  ? token.colorPrimaryBorderHover
                  : getTagColor(tagId)
              }
              icon={
                filteredTags.includes(tagId) ? (
                  <CloseCircleOutlined />
                ) : (
                  <CheckCircleOutlined />
                )
              }
            >
              {tags?.find((tag) => tag.id == tagId)?.value}
            </Tag>
          </div>
        ))}
      </Flex>
    );
  };

  const getSummaryBanner = () => {
    return (
      <Alert
        message={
          <Text style={{ marginTop: "-5px" }}>
            {getSubHeadingMessageForSearchResults()}
          </Text>
        }
        description={
          <Space size={2} direction="vertical">
            {mspEnabled && getTags()}
            {getApps()}
          </Space>
        }
        type="info"
        action={
          <Space direction="vertical">
            <Space style={{ position: "absolute", right: "20px" }}>
              <Text> Detailed View </Text>
              <Switch
                checked={showDetailedResults}
                size="small"
                onChange={setShowDetailedResults}
              />
            </Space>
          </Space>
        }
        style={{
          width: "99%",
          margin: token.marginXS,
          justifyContent: "space-between",
        }}
      />
    );
  };

  const handleActionClick = async () => {
    setShowActionsHistory(true);
  };

  const handleDropdownMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "actions" && handleActionClick();
  };

  const getMenuOptions = () => {
    return {
      items: headerDropDownItems,
      onClick: handleDropdownMenuClick,
    };
  };

  const getHeaderActionButtons = () => {
    return (
      <Space size={8}>
        <Space size={8}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setSaveSearchModal(true)}
          >
            Save search
          </Button>
          {currentSearchRun.status == SearchRunStatus.Running && (
            <Button
              type="primary"
              icon={<StopOutlined />}
              onClick={cancelSearch}
            >
              Stop Search
            </Button>
          )}
          {recommendedActions.length ? (
            <Button
              type="primary"
              icon={<SvgIcon component={workflowIcons.actionShortIcon} />}
              onClick={() => setShowActionPanel(true)}
            >
              Actions
            </Button>
          ) : null}
        </Space>
        <Dropdown placement="bottomLeft" menu={getMenuOptions()}>
          <Button
            icon={<SvgIcon component={commonIcons.moreOutlinedIcon} />}
            ghost
          />
        </Dropdown>
      </Space>
    );
  };

  const getHeaderControlButtons = () => {
    return (
      <Space size={1}>
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
      </Space>
    );
  };

  const getTitle = () => {
    let title = currentSearchRun.searchMetaData?.name;
    if (!title || title == "") {
      title = `search-runs/${searchRunId}`;
    }
    return (
      <Space size={8}>
        <Popover
          content={
            currentSearchRun.searchMetaData?.isAiBasedQuery
              ? currentSearchRun.searchMetaData.nativeQuery
              : title
          }
        >
          <Text style={{ fontSize: token.fontSizeHeading3 }}>
            Search Results
          </Text>
        </Popover>
        {showRunStatus()}
      </Space>
    );
  };

  useEffect(() => {
    if (
      currentSearchRun.status != SearchRunStatus.Running &&
      currentSearchRun.resultMetas &&
      currentSearchRun.resultMetas.length
    ) {
      const searchRunIdsList =
        getSearchRunIdsListFromSearchRun(currentSearchRun);
      if (searchRunIdsList != "") {
        getSearchRunActions(searchRunIdsList, 1, 50);
      }
    }
  }, [currentSearchRun]);

  useEffect(() => {
    if (!showActionPanel) {
      setShowActionPanel(
        Object.entries(searchRunActionSelectedDatas).length > 0
      );
    }
  }, [searchRunActionSelectedDatas]);
  return (
    <PageWrapper
      header={
        <div
          id="search-run-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: token.paddingXS,
            backgroundColor: token.colorBorderSecondary,
            alignItems: "center",
          }}
        >
          {getHeaderControlButtons()}
          {getTitle()}
          {getHeaderActionButtons()}
        </div>
      }
      content={
        <div id="search-run-container" className={classes.container}>
          {saveSearchModal && (
            <SaveSearchModal
              onClose={() => setSaveSearchModal(false)}
              loader={loader}
              open={saveSearchModal}
              onSubmit={(
                name: string,
                description: string,
                selectedTags?: string[],
                tagsOnly?: boolean
              ) => saveSearch(name, description, selectedTags, tagsOnly)}
            />
          )}
          <Layout
            style={{
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Space size={token.sizeXS} direction="vertical">
              {getSummaryBanner()}
              <Content
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div
                    id="search-run-table"
                    style={{
                      width:
                        currentSearchRun.resultMetas?.length &&
                        searchRunId &&
                        showActionPanel &&
                        recommendedActions.length
                          ? "75%"
                          : "100%",
                    }}
                  >
                    <Spin spinning={searchLoader || loader}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        {currentSearchRun.resultMetas?.length ? (
                          currentSearchRun.resultMetas
                            .sort((a, b) => (a.appName > b.appName ? -1 : 1))
                            .filter(
                              (rm) =>
                                rm.count > 0 &&
                                (filteredAppSubscriptions.length
                                  ? !filteredAppSubscriptions.includes(rm.appSubscriptionID)
                                  : true) &&
                                (filteredTags.length
                                  ? !filteredTags.includes(`${rm.tenantID}_tag`)
                                  : true)
                            )
                            .map((rm, index) => (
                              <ResultPanel
                                key={rm.appSubscriptionID}
                                index={index}
                                searchId={currentSearchRun.searchID}
                                appSubscriptionId={rm.appSubscriptionID}
                                artifactName={rm.artifactName}
                                resultMeta={rm}
                                editMode={true}
                                tagId={`${rm.tenantID}_tag`}
                              />
                            ))
                        ) : currentSearchRun.status ==
                          SearchRunStatus.Running ? (
                          <Skeleton
                            active={
                              currentSearchRun.status == SearchRunStatus.Running
                            }
                          />
                        ) : (
                          <Empty />
                        )}
                      </Space>
                    </Spin>
                  </div>
                  {searchRunId &&
                  showActionPanel &&
                  recommendedActions.length ? (
                    <div
                      id="search-action-drawer"
                      style={{
                        marginLeft: token.marginXXS,
                        width: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <ActionPanel
                        searchRunId={searchRunId}
                        artifactName={
                          currentSearchRun?.resultMetas?.[0]?.artifactName
                        }
                        open={showActionPanel}
                        onClose={() => setShowActionPanel(false)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Content>
            </Space>
          </Layout>
        </div>
      }
      feedback={
        <div>
          {searchRunId && showActionsHistory && (
            <ActionsHistory
              searchRunId={searchRunId}
              widthPercentage={"95%"}
              maxItems={10}
              open={showActionsHistory}
              onClose={() => setShowActionsHistory(false)}
            />
          )}
        </div>
      }
    ></PageWrapper>
  );
};
