import { FC, memo, } from "react";
import { useNavigate, } from "react-router-dom";
import { toPng } from "html-to-image";
import {Space, theme } from "antd";
import { useReactFlow, getRectOfNodes, getTransformForBounds} from '@reactflow/core';

import { commonIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import {ControlButton} from "components/ControlButton";
import { useWorkflowStore } from "store";
import html2canvas from "html2canvas";

export type EditorControlsProps = {
  editMode: boolean
};

const imageWidth = 4096;
const imageHeight = 2048;

const EditorControls: FC<EditorControlsProps> = ({
  editMode
}) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { zoomIn, zoomOut, fitView, getNodes } = useReactFlow();
  
//metric
  const { selectedWorkflow, changeSidebarCollapse, activeCopyId, setActiveCopyId, setActiveNodeCardId} = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
      changeSidebarCollapse: state.changeSidebarCollapse,
      activeCopyId: state.activeCopyId,
      setActiveCopyId: state.setActiveCopyId,
      setActiveNodeCardId: state.setActiveNodeCardId,
    })
  );

  const onZoomInHandler = () => {
    zoomIn();
  };

  const onZoomOutHandler = () => {
    zoomOut();
  };

  const onFitViewHandler = () => {
    fitView({maxZoom: 1});
    
  };

  const onScreenShotHandler = () => {
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    toPng(document.querySelector(".react-flow__viewport") as HTMLElement, {
      backgroundColor: token.colorBorderSecondary,
      width: imageWidth,
      height: imageHeight,
      style: {
        width: String(imageWidth),
        height: String(imageHeight),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
      }
    }).then( (downloadImage) => {
      const el = document.createElement("a");
      el.setAttribute("download", `${selectedWorkflow.name}.png`);
      el.setAttribute("href", downloadImage);
      el.click();
    }).catch((e) =>{
      console.log("failed to take screen shot", e)
    })
  }


  return (
    <Space size={1}>
      <ControlButton
        displayName={"Back"}
        svgComponent={
          <SvgIcon
            onClick={() => {
              navigate(-1);
            }}
            component={commonIcons.backIcon}
          />
        }
        hoverable={true}
      />
      {/* <ControlButton
          displayName={"Workflow Properties"}
          svgComponent={
            <SvgIcon
              onClick={() => {setActiveNodeCardId("workflow")}}
              component={commonIcons.editIcon}
            />
          }
          hoverable={true}
      /> */}
      <ControlButton
          displayName={"Take ScreenShot"}
          svgComponent={
            <SvgIcon
              onClick={() => {onScreenShotHandler()}}
              component={commonIcons.cameraIcon}
            />
          }
          hoverable={true}
      />
      <ControlButton
        displayName={"Zoom In"}
        svgComponent={
          <SvgIcon
            onClick={onZoomInHandler}
            component={commonIcons.zoomIn}
          />
        }
        hoverable={true}
      />
      <ControlButton
        displayName={"Zoom Out"}
        svgComponent={
          <SvgIcon
            onClick={onZoomOutHandler}
            component={commonIcons.zoomOut}
          />
        }
        hoverable={true}
      />
      <ControlButton
        displayName={"Fit View"}
        svgComponent={
          <SvgIcon
            onClick={onFitViewHandler}
            component={commonIcons.fitView}
          />
        }
        hoverable={true}
      />
      {editMode &&
        <ControlButton
          displayName={"Show Sidebar"}
          svgComponent={
            <SvgIcon
              onClick={changeSidebarCollapse}
              component={workflowIcons.stepsSidebarCollapse}
            />
          }
          hoverable={true}
        />
      }
      {editMode &&
        <ControlButton
          displayName={"Clear Clipboard"}
          svgComponent={
            <SvgIcon
              onClick={() => activeCopyId != null && setActiveCopyId(null)}
              component={commonIcons.clipBoardIcon}
            />
          }
          hoverable={activeCopyId != null}
        />
      }
    </Space>
  );
};
export default memo(EditorControls);
