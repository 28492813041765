import { FC, useState } from "react";

import {
  Button,
  notification,
  Space,
  Spin,
  Tabs,
  theme,
  Tooltip,
  type TabsProps,
} from "antd";
import { dashboardIcons } from "assets/icons";
import { CustomDashboardTab } from "components/Dashboard/CustomDashboardTab";
import { DashboardNew } from "components/Dashboard/DashboardNew";
import { HyprEdgeDashboard } from "components/Dashboard/HyprEdgeDashboard";
import { SvgIcon } from "components/SvgIcon";
import { useDashboardStore, useGoalStore } from "store";
import { takeScreenShotByElementId } from "utility";

import PageWrapper from "../../components/PageWrapper";

const items: TabsProps["items"] = [
  {
    key: "hypredge-dashboard",
    label: "HyprEdge Dashboard",
    children: <HyprEdgeDashboard />,
  },
  {
    key: "custom-dashboard",
    label: "My Dashboards",
    children: <CustomDashboardTab />,
  },
];

const DashBoardWrap: FC = () => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [newDashboard, setNewDashboard] = useState(false);
  const [activeKey, setActiveKey] = useState("hypredge-dashboard");
  const listGoals = useGoalStore((state) => state.listGoals);
  const dashboardEdit = useDashboardStore((state) => state.dashboardEdit);

  const onScreenShotHandler = () => {
    let dashboardElementId = "dashboard-metrics";
    let dashboardNameSuffix = "hypredge_dashboard";
    if (activeKey == "custom-dashboard") {
      dashboardElementId = "custom-dashboards";
      dashboardNameSuffix = "my-dashboard";
    }
    takeScreenShotByElementId(
      dashboardElementId,
      dashboardNameSuffix,
      token.colorBorderSecondary
    );
  };

  const onRefreshHandler = async () => {
    setLoading(true);
    try {
      await listGoals();
    } catch (error) {
      notification.error({
        message: "Failed to refresh dashboard",
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageWrapper
      content={
        <div style={{ width: "100%" }}>
          <Spin spinning={loading} size="large">
            <Tabs
              defaultActiveKey={activeKey}
              onChange={(key) => setActiveKey(key)}
              items={items}
              style={{ margin: token.margin }}
              tabBarExtraContent={
                activeKey == "hypredge-dashboard" ? null : (
                  <Space size={"small"}>
                    <Tooltip
                      title={"Refresh"}
                      color={token.colorPrimaryText}
                      placement="top"
                    >
                      <Button
                        icon={
                          <SvgIcon
                            size={30}
                            component={dashboardIcons.dashboardRefresh}
                          />
                        }
                        onClick={onRefreshHandler}
                        disabled={dashboardEdit !== null}
                      />
                    </Tooltip>
                    <Tooltip
                      title={"Add Dashboard"}
                      color={token.colorPrimaryText}
                      placement="top"
                    >
                      <Button
                        icon={
                          <SvgIcon
                            size={30}
                            component={dashboardIcons.dashboardAdd}
                          />
                        }
                        onClick={() => setNewDashboard(true)}
                      />
                    </Tooltip>
                    <Tooltip
                      title={"Take Screenshot"}
                      color={token.colorPrimaryText}
                      placement="top"
                    >
                      <Button
                        icon={
                          <SvgIcon
                            size={30}
                            component={dashboardIcons.dashboardScreenshot}
                          />
                        }
                        onClick={() => onScreenShotHandler()}
                      />
                    </Tooltip>
                  </Space>
                )
              }
            />
            {newDashboard && (
              <DashboardNew
                open={newDashboard}
                onClose={async () => setNewDashboard(false)}
              />
            )}
          </Spin>
        </div>
      }
    />
  );
};

export default DashBoardWrap;
