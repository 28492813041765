import {
  Tabs,
} from "antd";

import { FC } from "react";

import { PoliciesTab } from "./policiesTab";
import { PolicyCategoryType } from "types";
import { ActionsTab } from "./actionsTab";
import { ArtifactsTab } from "./artifactsTab";
import { TriggersTab } from "./triggersTab";

export const Policies: FC = () => {

  return (
    <Tabs
      items={
        [
          {
            key: "1",
            label: "Workflows",
            children: <PoliciesTab type={PolicyCategoryType.WorkflowGeneral}/>,
          },
          {
            key: "2",
            label: "Search",
            children: <PoliciesTab type={PolicyCategoryType.SearchGeneral}/>,
          },
          {
            key: "3",
            label: "Cases",
            children: <PoliciesTab type={PolicyCategoryType.CaseMgmtGeneral}/>,
          },
          {
            key: "4",
            label: "Triggers",
            children: <TriggersTab type={PolicyCategoryType.TriggerProviders}/>,
          },
          {
            key: "5",
            label: "Actions",
            children: <ActionsTab type={PolicyCategoryType.ActionProviders}/>,
          },
          {
            key: "6",
            label: "Artifacts",
            children: <ArtifactsTab type={PolicyCategoryType.ArtifactProviders}/>,
          },
        ]
      }
    />
  );
}